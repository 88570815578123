import styled from 'styled-components';

export const ListContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  @media (max-width: 752px) {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
