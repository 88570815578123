import styled, { css } from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex: 1;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border-width: 0;
  border-radius: 16px;

  padding: 28px 16px;

  ${({ theme }) => css`
    background: none;
    border: 2px solid ${theme.colors.border};
  `}

  svg {
    margin-right: 16px;
  }

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    text-align: start;

    p {
      font-size: 0.9rem;
    }
  }
`;
