import State from '../models/State';
import api from './api';

export async function getAll(): Promise<State[]> {
  const response = await api.get<State[]>('states');

  return response.data;
}

export async function getById(cityId: string): Promise<State> {
  const response = await api.get<State>(`states/${cityId}`);

  return response.data;
}
