import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { Container } from './styles';

import * as AccountabilityRequestService from '../../services/AccountabilityRequestService';
import AccountabilityType from '../../models/AccountabilityType';
import BankAccount from '../../models/BankAccount';
import * as BankAccountService from '../../services/BankAccountService';
import * as AccountabilityTypesService from '../../services/AccountabilityTypesService';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';
import { useLoading } from '../../contexts/loadingContext';

interface IFormAccountabilityRequest {
  id?: string;
  year: number;
  bankAccountId: string;
  accountabilityTypeId: number;
}

interface IParams {
  id: string;
}

const schemaAccountabilityRequestForm = yup.object().shape({
  id: yup.string().uuid(),
  year: yup
    .number()
    .typeError('Campo precisa ser um número.')
    .min(2000, "Campo precisa ser um número entre '2000' e '2050'.")
    .max(2050, "Campo precisa ser um número entre '2000' e '2050'.")
    .required('Campo obrigatório.'),
  bankAccountId: yup.string().required('Campo obrigatório.'),
  accountabilityTypeId: yup.string().required('Campo obrigatório.'),
});

function AccountabilityRequestsForm(): JSX.Element {
  const params = useParams<IParams>();
  const history = useHistory();
  const { currentCity, enableButton, disableButton } = useCurrentCity();
  const { addToast } = useToast();
  const { openLoading, closeLoading } = useLoading();

  const { handleSubmit, control, reset } = useForm<IFormAccountabilityRequest>({
    resolver: yupResolver(schemaAccountabilityRequestForm),
  });

  const [accountabilityTypes, setAccountabilityTypes] = useState<
    AccountabilityType[]
  >([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const onSubmit = useCallback(
    async ({
      year,
      bankAccountId,
      accountabilityTypeId,
    }: IFormAccountabilityRequest) => {
      openLoading();
      if (params.id !== 'new') {
        try {
          await AccountabilityRequestService.update({
            id: params.id,
            year,
            cityId: currentCity?.id as number,
            bankAccountId,
            accountabilityTypeId,
          });
          addToast({
            message: 'Solicitação de prestação de conta atualizado.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message:
              'Não foi possível atualizar a solicitação de prestação de conta',
            type: 'error',
          });
        }
      } else {
        try {
          await AccountabilityRequestService.create({
            year,
            cityId: currentCity?.id as number,
            bankAccountId,
            accountabilityTypeId,
          });
          addToast({
            message: 'Solicitação de prestação de conta cadastrado.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message:
              'Não foi possível salvar a solicitação de prestação de conta',
            type: 'error',
          });
        }
      }
      closeLoading();
    },
    [params.id, history, currentCity, addToast, openLoading, closeLoading]
  );

  const loadAccountabilityRequest = useCallback(async () => {
    if (params.id !== 'new') {
      try {
        const form = await AccountabilityRequestService.getById(params.id);
        reset({ ...form });
      } catch (error) {
        addToast({
          message:
            'Não foi possível carregar a solicitação de prestação de conta.',
          type: 'error',
        });
        history.goBack();
      }
    }
  }, [params.id, reset, addToast, history]);

  const loadAccountabilityTypes = useCallback(async () => {
    try {
      setAccountabilityTypes(await AccountabilityTypesService.getAll());
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar os tipos de prestação de conta.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history]);

  const loadBankAccounts = useCallback(async () => {
    try {
      setBankAccounts(
        (await BankAccountService.getAll({ cityId: currentCity?.id })).items
      );
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar as contas bancárias.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history, currentCity]);

  useEffect(() => {
    enableButton();
    return () => {
      disableButton();
    };
  }, [enableButton, disableButton]);

  useEffect(() => {
    async function load() {
      openLoading();
      await loadAccountabilityTypes();
      await loadBankAccounts();
      await loadAccountabilityRequest();
      closeLoading();
    }

    load();
  }, [
    loadAccountabilityRequest,
    loadAccountabilityTypes,
    loadBankAccounts,
    openLoading,
    closeLoading,
  ]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="year"
          control={control}
          label="Ano da prestação"
          type="number"
        />
        <Select
          name="bankAccountId"
          control={control}
          label="Conta bancaria"
          data={bankAccounts}
        />
        <Select
          name="accountabilityTypeId"
          control={control}
          label="Tipo da prestação de conta"
          data={accountabilityTypes}
        />
        <Button type="submit" text="SALVAR" />
      </form>
    </Container>
  );
}

export { AccountabilityRequestsForm };
