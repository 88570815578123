import { ReactNode, useMemo } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Container, TableContainer, PaginationWrapper } from './styles';

interface TableProps {
  children: ReactNode;
  pagination: any;
}

function Table({ children, pagination }: TableProps): JSX.Element {
  const renderPages = useMemo(() => {
    const rows = [];
    for (let i = 1; i <= pagination.totalPage; i += 1) {
      if (i === 1) {
        rows.push(i);
      } else if (i === pagination.totalPage) {
        rows.push(i);
      } else if (i === pagination.page - 1) {
        rows.push('...');
        rows.push(i);
      } else if (i === pagination.page + 1) {
        rows.push(i);
        rows.push('...');
      } else if (i === pagination.page) rows.push(i);
    }
    return rows;
  }, [pagination]);

  return (
    <Container>
      <TableContainer>{children}</TableContainer>
      <PaginationWrapper>
        <button
          type="button"
          onClick={pagination.previousPage}
          disabled={pagination.previousPageDisabled}
        >
          <MdChevronLeft />
        </button>
        {renderPages.map((pageNumber, index) => (
          <button
            key={Number(index)}
            type="button"
            onClick={() =>
              typeof pageNumber === 'number' && pagination.goPage(pageNumber)
            }
            disabled={pagination.page === pageNumber}
          >
            {pageNumber}
          </button>
        ))}
        <button
          type="button"
          onClick={pagination.nextPage}
          disabled={pagination.nextPageDisabled}
        >
          <MdChevronRight />
        </button>
      </PaginationWrapper>
    </Container>
  );
}

export default Table;
