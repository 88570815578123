import { useCallback, useMemo, useEffect } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container, ButtonActionTable } from './styles';

import Button from '../../components/Button';

import * as BankAccountService from '../../services/BankAccountService';

import BankAccount from '../../models/BankAccount';
import Table from '../../components/Table';
import { usePagination } from '../../hooks/usePagination';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';

function BankAccountList(): JSX.Element {
  const history = useHistory();
  const { addToast } = useToast();
  const { currentCity } = useCurrentCity();

  const params = useMemo(() => ({ cityId: currentCity?.id }), [currentCity]);

  const {
    items: bankAccounts,
    removeItem,
    error,
    ...paginationProps
  } = usePagination<BankAccount>({
    url: '/bankAccounts',
    pageSize: 10,
    params,
  });

  const handleNewBankAccount = useCallback(() => {
    history.push(`/bankAccounts/new`);
  }, [history]);

  const handleDeleteBankAccount = useCallback(
    async (bankAccountId: string | undefined) => {
      if (bankAccountId) {
        try {
          await BankAccountService.remove(bankAccountId);
          removeItem(bankAccountId);
          addToast({
            message: 'Conta bancaria deletada.',
            type: 'success',
          });
        } catch (err) {
          addToast({
            message: 'Não foi possível deletar a conta bancaria.',
            type: 'error',
          });
        }
      }
    },
    [removeItem, addToast]
  );

  const handleEditBankAccount = useCallback(
    (bankAccountId: string | undefined) => {
      if (bankAccountId) {
        history.push(`/bankAccounts/${bankAccountId}`);
      }
    },
    [history]
  );

  useEffect(() => {
    if (error) addToast({ message: error, type: 'error' });
  }, [error, addToast]);

  return (
    <Container>
      <div>
        <Button onClick={handleNewBankAccount} text="NOVA CONTA BANCÁRIA" />
      </div>
      <Table pagination={paginationProps}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Argencia</th>
            <th>Conta</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {bankAccounts.map((bankAccount) => (
            <tr key={bankAccount.id}>
              <td>{bankAccount.name}</td>
              <td>{bankAccount.argency}</td>
              <td>{bankAccount.account}</td>
              <td>{bankAccount.accountType?.name}</td>
              <td>
                <ButtonActionTable
                  onClick={() => handleEditBankAccount(bankAccount.id)}
                >
                  <MdEdit />
                </ButtonActionTable>
                <ButtonActionTable
                  onClick={() => handleDeleteBankAccount(bankAccount.id)}
                >
                  <MdDelete />
                </ButtonActionTable>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export { BankAccountList };
