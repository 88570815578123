import { createContext, ReactNode, useState } from 'react';
import {
  ThemeProvider as StyledThemeProvier,
  DefaultTheme,
} from 'styled-components';

import themeMain from '../styles/themes/main';

interface ThemeContextProviderData {
  theme: DefaultTheme;
}

interface ThemeContextProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext({} as ThemeContextProviderData);

export function ThemeContextProvider({
  children,
}: ThemeContextProviderProps): JSX.Element {
  const [theme] = useState<DefaultTheme>(themeMain);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <StyledThemeProvier theme={theme}>{children}</StyledThemeProvier>
    </ThemeContext.Provider>
  );
}
