import { Toast } from './components/Toast';
import { ListContainer } from './styles';
import { IToastMessage } from '../../contexts/toastContext';

interface ToastContainerProps {
  toastMessages: IToastMessage[];
}

function ToastContainer({ toastMessages }: ToastContainerProps): JSX.Element {
  return (
    <ListContainer>
      {toastMessages.map((toast) => (
        <Toast key={toast.id} toastMessage={toast} />
      ))}
    </ListContainer>
  );
}

export default ToastContainer;
