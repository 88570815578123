import { ReactNode, useCallback, useMemo } from 'react';

import { FaChevronLeft } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BackButtun,
  Container,
  ContentContainer,
  HeaderContainer,
} from './styles';

import HeaderProfile from '../HeaderProfile';
import { GovTop } from '../GovTop';
import { GovLogos } from '../GovLogos';
import { Breadcrumb } from '../Breadcrumb';

interface MainContainerProps {
  children: ReactNode;
}

export default function MainContainer({
  children,
}: MainContainerProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const canGoBack = useMemo(
    () =>
      location.pathname !== '/dashboard' &&
      location.pathname !== '/' &&
      !location.pathname.includes('/reset'),
    [location.pathname]
  );

  const handleOnBackPress = useCallback(() => {
    if (canGoBack) history.goBack();
  }, [history, canGoBack]);

  return (
    <Container>
      <GovTop />
      {/* <GovLogos /> */}
      <HeaderContainer>
        <HeaderProfile />
      </HeaderContainer>
      <ContentContainer>
        {canGoBack && (
          <BackButtun onClick={handleOnBackPress}>
            <FaChevronLeft />
            VOLTAR
          </BackButtun>
        )}
        <Breadcrumb />
        {children}
      </ContentContainer>
    </Container>
  );
}
