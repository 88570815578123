import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  height: 128px;

  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);

  @media (min-width: 848px) {
    align-self: center;
    border-radius: 16px;
    width: 848px;
  }

  ${({ theme }) =>
    css`
      background: ${theme.colors.primary};
    `}
`;

export const ContentContainer = styled.div`
  background: white;

  @media (min-width: 752px) {
    align-self: center;
    width: 720px;
  }

  max-width: 720px;
  margin: -25px 16px 16px;

  padding: 16px;

  border-radius: 8px;

  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;

export const BackButtun = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 16px 8px;
  font-size: 1.1rem;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-right: 4px;
  }
`;
