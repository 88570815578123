import styled, { css } from 'styled-components';

interface InputWrapperProps {
  isFocused: boolean;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;

  margin: 8px 0;

  label {
    margin-bottom: 4px;
  }

  span {
    font-size: 0.75rem;
    margin-top: 4px;

    ${({ theme }) =>
      css`
        color: ${theme.colors.error};
      `}
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex: 1;

  align-items: center;

  height: 40px;

  border: 0px;
  border-radius: 8px;

  ${({ theme }) =>
    css`
      border: 2px solid ${theme.colors.border};
    `}
  ${({ theme, isFocused }) =>
    isFocused &&
    css`
      border: 2px solid ${theme.colors.primary};
    `}

  input {
    display: flex;
    background-color: white;
    flex: 1;
    border: none;
    margin: 8px 8px;
  }

  svg {
    padding: 2px;
    margin-right: 8px;
  }

  svg:hover {
    background-color: lightgray;
    border-radius: 50%;
  }
`;
