import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TableContainer = styled.table`
  margin: 4px 0;

  thead {
    th:first-child {
      text-align: start;
    }

    th {
      padding: 8px 4px;
      text-align: center;
    }
  }

  tbody {
    td:first-child {
      width: 100%;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td {
      min-width: 75px;
      padding: 8px 4px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex: 1;

  button {
    background: none;
    border: 0;
    margin: 2px;
    width: 18px;
    height: 24px;

    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;
