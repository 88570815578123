import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import SelectCityModal from '../components/SelectCityModal';
import { useProfile } from './profileContext';
import City from '../models/City';

interface CurrentCityContextProps {
  children: ReactNode;
}

export interface CurrentCityContextData {
  currentCity?: City;
  openModal: () => void;
  closeModal: () => void;
  enableButton: () => void;
  disableButton: () => void;
  isDisableButton: boolean;
  setCurrentCity: (city: City) => void;
}

const CurrentCityContext = createContext<CurrentCityContextData>(
  {} as CurrentCityContextData
);

function CurrentCityProvider({
  children,
}: CurrentCityContextProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const [isDisableButton, setDisableButton] = useState(false);
  const { profile } = useProfile();

  const [city, setCity] = useState<City>();

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  const enableButton = useCallback(() => {
    setDisableButton(true);
  }, []);

  const disableButton = useCallback(() => {
    setDisableButton(false);
  }, []);

  const setCurrentCity = useCallback((newCity: City) => {
    setCity(newCity);
  }, []);

  useEffect(() => {
    if (!profile) return;

    setCity(profile.city);
  }, [profile]);

  return (
    <CurrentCityContext.Provider
      value={{
        currentCity: city,
        openModal,
        closeModal,
        setCurrentCity,
        enableButton,
        disableButton,
        isDisableButton,
      }}
    >
      {children}
      <SelectCityModal
        isVisible={isVisible}
        closeModal={closeModal}
        setCurrentCity={setCurrentCity}
      />
    </CurrentCityContext.Provider>
  );
}

function useCurrentCity(): CurrentCityContextData {
  const context = useContext(CurrentCityContext);

  return context;
}

export { CurrentCityProvider, useCurrentCity };
