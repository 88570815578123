import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useProfile } from '../../contexts/profileContext';
import { useStateCity } from '../../contexts/stateCityContext';
import City from '../../models/City';
import Button from '../Button';
import { Modal } from '../Modal';
import Select from '../Select';

interface SelectCityModalProps {
  isVisible: boolean;
  closeModal: () => void;
  setCurrentCity: (city: City) => void;
}

interface IFormData {
  cityId: number;
  stateId: number;
}

function SelectCityModal({
  isVisible,
  closeModal,
  setCurrentCity,
}: SelectCityModalProps): JSX.Element {
  const { control, handleSubmit, reset, watch } = useForm<IFormData>();
  const { cities, statesByPermission: states } = useStateCity();
  const { profile } = useProfile();

  const watchStateId = watch('stateId', 0);

  const onSubmit = useCallback(
    (data: IFormData) => {
      const asd = cities.find(
        (city) => city.id === Number(data.cityId)
      ) as City;
      setCurrentCity(asd);
      closeModal();
    },
    [closeModal, setCurrentCity, cities]
  );

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const listCitiesByState = useMemo(
    () =>
      watchStateId
        ? cities.filter((city) => city.stateId === Number(watchStateId) && city)
        : cities,
    [watchStateId, cities]
  );

  useEffect(() => {
    if (!profile) return;

    reset({ cityId: profile.cityId, stateId: profile.city?.stateId });
  }, [profile, reset]);

  return (
    <Modal
      isOpen={isVisible}
      title="Selecionar cidade"
      onRequestClose={closeModal}
    >
      <Select name="stateId" label="Estado:" control={control} data={states} />
      <Select
        name="cityId"
        label="Cidade:"
        control={control}
        data={listCitiesByState}
      />
      <div className="modal-footer">
        <Button text="CANCELAR" onClick={handleCloseModal} />
        <Button text="SALVAR" onClick={handleSubmit(onSubmit)} />
      </div>
    </Modal>
  );
}

export default SelectCityModal;
