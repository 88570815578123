import styled from 'styled-components';

interface ContainerProps {
  isVisible: boolean;
}

export const ButtonWrapper = styled.button`
  border: none;
  background: none;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.ul<ContainerProps>`
  position: relative;
  min-width: 150px;

  overflow: hidden;

  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  border-radius: 8px;

  @media (max-width: 1024px) {
    position: absolute;
  }
  right: 0%;

  margin: 4px;

  background-color: white;
  color: black;

  list-style-type: none;

  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);

  button {
    border: none;
    background: none;

    padding: 8px;

    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }

    li {
      display: flex;
    }
  }
`;
