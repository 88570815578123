import City from '../models/City';
import api from './api';

export async function getAll(): Promise<City[]> {
  const response = await api.get<City[]>('cities');

  return response.data;
}

export async function getById(cityId: string): Promise<City> {
  const response = await api.get<City>(`cities/${cityId}`);

  return response.data;
}
