import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Container } from './styles';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';
import * as BankAccountService from '../../services/BankAccountService';
import * as AccountTypesService from '../../services/AccountTypesService';
import AccountType from '../../models/AccountType';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';
import { useLoading } from '../../contexts/loadingContext';

interface IFormBankAccount {
  id?: string;
  name: string;
  argency: number;
  account: number;
  accountTypeId: number;
}

interface IParams {
  id: string;
}

const schemaBankAccountForm = yup.object().shape({
  id: yup.string().uuid(),
  name: yup.string().required('Campo obrigatório.'),
  argency: yup
    .number()
    .typeError('Campo precisa ser um número.')
    .required('Campo obrigatório.'),
  account: yup
    .number()
    .typeError('Campo precisa ser um número.')
    .required('Campo obrigatório.'),
  accountTypeId: yup
    .number()
    .typeError('Campo obrigatório.')
    .required('Campo obrigatório.'),
});

function BankAccountForm(): JSX.Element {
  const params = useParams<IParams>();
  const { addToast } = useToast();
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const { currentCity, enableButton, disableButton } = useCurrentCity();

  const { control, handleSubmit, reset } = useForm<IFormBankAccount>({
    resolver: yupResolver(schemaBankAccountForm),
    reValidateMode: 'onSubmit',
  });

  const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);

  const onSubmit = useCallback(
    async ({ account, accountTypeId, argency, name }: IFormBankAccount) => {
      openLoading();
      if (params.id !== 'new') {
        try {
          await BankAccountService.update({
            id: params.id,
            account,
            accountTypeId,
            argency,
            name,
            cityId: currentCity?.id as number,
          });
          addToast({
            message: 'Conta bancária atualizado.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message: 'Não foi possível atualizar a conta bancária',
            type: 'error',
          });
        }
      } else {
        try {
          await BankAccountService.create({
            account,
            accountTypeId,
            argency,
            name,
            cityId: currentCity?.id as number,
          });
          addToast({
            message: 'Conta bancária cadastrada.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message: 'Não foi possível salvar a conta bancária',
            type: 'error',
          });
        }
      }
      closeLoading();
    },
    [params, history, currentCity, addToast, openLoading, closeLoading]
  );

  const loadBankAccount = useCallback(async () => {
    if (params.id !== 'new') {
      try {
        const form = await BankAccountService.getById(params.id);
        reset({ ...form });
      } catch (error) {
        addToast({
          message: 'Não foi possível carregar a conta bancária.',
          type: 'error',
        });
        history.goBack();
      }
    }
  }, [params.id, reset, addToast, history]);

  const loadAccountTypes = useCallback(async () => {
    try {
      setAccountTypes(await AccountTypesService.getAll());
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar os tipos de conta.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history]);

  useEffect(() => {
    enableButton();
    return () => {
      disableButton();
    };
  }, [enableButton, disableButton]);

  useEffect(() => {
    async function load() {
      openLoading();
      await loadAccountTypes();
      await loadBankAccount();
      closeLoading();
    }

    load();
  }, [loadBankAccount, loadAccountTypes, openLoading, closeLoading]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input name="name" control={control} label="Nome" />
        <Input name="argency" control={control} label="Agencia" />
        <Input name="account" control={control} label="Conta" />
        <Select
          name="accountTypeId"
          control={control}
          label="Tipo da conta"
          data={accountTypes}
        />
        <Button type="submit" text="SALVAR" />
      </form>
    </Container>
  );
}

export { BankAccountForm };
