import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;

    display: flex;
    flex-direction: column;

    flex: 1;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: gray;
    }
  }
`;
