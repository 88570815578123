import styled, { css, DefaultTheme } from 'styled-components';

interface ContainerProps {
  type: 'info' | 'success' | 'error' | 'warning';
}

const toastTypeVariations = (type: string, theme: DefaultTheme) => {
  switch (type) {
    case 'info':
      return css`
        background: ${theme.colors.info};
      `;
    case 'success':
      return css`
        background: ${theme.colors.success};
      `;
    case 'error':
      return css`
        background: ${theme.colors.error};
      `;
    case 'warning':
      return css`
        background: ${theme.colors.warning};
      `;
    default:
      return css``;
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 400px;
  min-height: 100px;

  border-radius: 8px;

  margin: 8px 16px;

  padding: 16px;

  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);

  ${({ type, theme }) => toastTypeVariations(type, theme)}

  @media (max-width: 752px) {
    width: 95vw;
  }

  h2,
  p {
    color: ${({ theme }) => theme.colors.textWhite};
    word-break: normal;
    width: 350px;
  }

  button {
    position: absolute;
    top: 4px;
    right: 4px;

    padding: 8px;
    margin: 8px;

    background: none;
    border: 0;

    background: transparent;
    color: ${({ theme }) => theme.colors.textWhite};

    svg {
      font-size: 18px;
    }
  }
`;

export const CloseButton = styled.button``;
