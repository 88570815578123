import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0 16px 25px;

  color: ${({ theme }) => theme.colors.textWhite};
`;

export const Profile = styled.div`
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: flex-end;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  margin-right: 8px;

  button {
    margin-top: 4px;
  }
`;

export const CityButton = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  background: white;
  border-radius: 4px;
  padding: 4px;
  border: 0;

  svg {
    margin-left: 4px;
  }
`;

export const ProfilePicture = styled.div`
  background: white;
  border-radius: 25%;
  height: 50px;
  width: 50px;
`;
