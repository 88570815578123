import Accountability from '../models/Accountability';
import api from './api';

export async function getAll(): Promise<Accountability[]> {
  const response = await api.get<Accountability[]>('accountability');

  return response.data;
}

export async function getById(
  accountabilityId: string
): Promise<Accountability> {
  const response = await api.get<Accountability>(
    `accountability/${accountabilityId}`
  );

  return response.data;
}

export async function create(acoountability: any): Promise<void> {
  const data = new FormData();

  data.append('openingBalance', String(acoountability.openingBalance));
  data.append('resourceReceived', String(acoountability.resourceReceived));
  data.append('incomeReceived', String(acoountability.incomeReceived));
  data.append('otherIncome', String(acoountability.otherIncome));
  data.append('returnedAmount', String(acoountability.returnedAmount));
  data.append(
    'previousAccountBalance',
    String(acoountability.previousAccountBalance)
  );
  data.append('totalIncome', String(acoountability.totalIncome));
  data.append('costExpense', String(acoountability.costExpense));
  data.append('investmentExpense', String(acoountability.investmentExpense));
  data.append('amountPaid', String(acoountability.amountPaid));
  data.append('finalBalance', String(acoountability.finalBalance));
  data.append(
    'reschedulingBalance',
    String(acoountability.reschedulingBalance)
  );
  data.append(
    'accountabilityRequestId',
    acoountability.accountabilityRequestId
  );
  data.append(
    'PCAForwardingOfficeAttachment',
    acoountability.PCAForwardingOfficeAttachment[0] as File
  );
  data.append(
    'executionWorksheetAttachment',
    acoountability.executionWorksheetAttachment[0] as File
  );
  data.append(
    'bankStatementsAttachment',
    acoountability.bankStatementsAttachment[0] as File
  );

  await api.post(`accountability`, data);
}

export async function update(accountability: any): Promise<void> {
  const data = new FormData();

  data.append('openingBalance', String(accountability.openingBalance));
  data.append('resourceReceived', String(accountability.resourceReceived));
  data.append('incomeReceived', String(accountability.incomeReceived));
  data.append('otherIncome', String(accountability.otherIncome));
  data.append('returnedAmount', String(accountability.returnedAmount));
  data.append(
    'previousAccountBalance',
    String(accountability.previousAccountBalance)
  );
  data.append('totalIncome', String(accountability.totalIncome));
  data.append('costExpense', String(accountability.costExpense));
  data.append('investmentExpense', String(accountability.investmentExpense));
  data.append('amountPaid', String(accountability.amountPaid));
  data.append('finalBalance', String(accountability.finalBalance));
  data.append(
    'reschedulingBalance',
    String(accountability.reschedulingBalance)
  );
  data.append(
    'accountabilityRequestId',
    accountability.accountabilityRequestId
  );
  if (accountability.PCAForwardingOfficeAttachment[0])
    data.append(
      'PCAForwardingOfficeAttachment',
      accountability.PCAForwardingOfficeAttachment[0] as File
    );
  if (accountability.executionWorksheetAttachment[0])
    data.append(
      'executionWorksheetAttachment',
      accountability.executionWorksheetAttachment[0] as File
    );
  if (accountability.bankStatementsAttachment[0])
    data.append(
      'bankStatementsAttachment',
      accountability.bankStatementsAttachment[0] as File
    );

  await api.put(`accountability/${accountability.id}`, data);
}
