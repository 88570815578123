import { ChangeEvent, useCallback, useRef } from 'react';
import {
  Control,
  FieldValue,
  FieldValues,
  useController,
} from 'react-hook-form';
import { MdFileUpload } from 'react-icons/md';

import { Container, FileContainer, ButtonViewFile } from './styles';

interface FileInputProps {
  control: Control<FieldValue<FieldValues>>;
  label: string;
  name: string;
  disabled: boolean;
}

function FileInput({
  control,
  label,
  name,
  disabled,
}: FileInputProps): JSX.Element {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: [],
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.files || null);
    },
    [onChange]
  );

  const handleViewFile = useCallback(() => {
    if (value[0]?.url) window.open(value[0].url, '_blank');
  }, [value]);

  const handleChangeInput = useCallback(() => {
    if (disabled) {
      handleViewFile();
    }
    fileInputRef.current?.click();
  }, [handleViewFile, disabled]);

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <input
        ref={fileInputRef}
        type="file"
        name={name}
        onChange={onChangeInput}
      />
      <FileContainer>
        {value.length ? (
          <p>
            {!disabled ? (
              <ButtonViewFile type="button" onClick={handleChangeInput}>
                Selecione outro arquivo...
              </ButtonViewFile>
            ) : null}

            <ButtonViewFile type="button" onClick={handleViewFile}>
              <span>{value[0]?.name || value[0]?.fileName}</span>
            </ButtonViewFile>
          </p>
        ) : (
          <ButtonViewFile type="button" onClick={handleChangeInput}>
            <MdFileUpload size={34} color="gray" />
            <p>Selecione um arquivo...</p>
          </ButtonViewFile>
        )}
      </FileContainer>
      {error && <span>{error.message}</span>}
    </Container>
  );
}

export default FileInput;
