import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Background, Container } from './styles';

interface LoadingProps {
  isLoading: boolean;
  message: string;
}

function Loading({ isLoading, message }: LoadingProps): JSX.Element {
  return (
    <Background isVisible={isLoading}>
      <Container>
        <AiOutlineLoading3Quarters />
        <p>{message}</p>
      </Container>
    </Background>
  );
}

export default Loading;
