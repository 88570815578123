import { useCallback, useEffect, useMemo } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { usePagination } from '../../hooks/usePagination';

import Button from '../../components/Button';
import Table from '../../components/Table';

import * as UserService from '../../services/UserService';

import User from '../../models/User';

import { Container, ButtonActionTable } from './styles';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';

function UsersList(): JSX.Element {
  const history = useHistory();
  const { addToast } = useToast();
  const { currentCity } = useCurrentCity();

  const params = useMemo(() => ({ cityId: currentCity?.id }), [currentCity]);

  const {
    items: users,
    removeItem,
    error,
    ...paginationProps
  } = usePagination<User>({
    url: '/users',
    pageSize: 10,
    params,
  });

  const handleNewUser = useCallback(() => {
    history.push('/users/new');
  }, [history]);

  const handleDeleteUser = useCallback(
    async (userId: string | undefined) => {
      if (userId) {
        try {
          await UserService.remove(userId);
          removeItem(userId);
          addToast({
            message: 'Usuário deletado.',
            type: 'success',
          });
        } catch (err) {
          addToast({
            message: 'Não foi possível deletar o usuário.',
            type: 'error',
          });
        }
      }
    },
    [removeItem, addToast]
  );

  const handleEditUser = useCallback(
    (userId: string | undefined) => {
      if (userId) {
        history.push(`/users/${userId}`);
      }
    },
    [history]
  );

  useEffect(() => {
    if (error) addToast({ message: error, type: 'error' });
  }, [error, addToast]);

  return (
    <Container>
      <div>
        <Button onClick={handleNewUser} text="NOVO USUARIO" />
      </div>
      <Table pagination={paginationProps}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cargo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.role?.name}</td>
              <td>
                <ButtonActionTable
                  onClick={() => {
                    handleEditUser(user.id);
                  }}
                >
                  <MdEdit />
                </ButtonActionTable>
                <ButtonActionTable
                  onClick={() => {
                    handleDeleteUser(user.id);
                  }}
                >
                  <MdDelete />
                </ButtonActionTable>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export { UsersList };
