import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;

  div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Table = styled.table`
  margin: 4px 0;

  thead {
    th:first-child {
      text-align: start;
    }

    th {
      padding: 8px 4px;
      text-align: center;
    }
  }

  tbody {
    td:first-child {
      width: 100%;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td:nth-child(2) {
      min-width: 175px;
    }

    td {
      min-width: 75px;
      padding: 8px 4px;
      text-align: center;
    }
  }
`;

export const ButtonActionTable = styled.button`
  background: none;
  border: none;
  padding: 1px 4px;

  &:hover {
    background: lightgray;
    border-radius: 4px;
  }
`;
