import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Container, BankAccountWrapper, DownloadsWrapper } from './styles';

import Button from '../../components/Button';
import Input from '../../components/Input';

import * as AccountabilityRequestService from '../../services/AccountabilityRequestService';
import * as AccountabilityService from '../../services/AccountabilityService';
import AccountabilityRequest from '../../models/AccountabilityRequest';
import { formatCurrencyToNumber } from '../../utils';
import FileInput from '../../components/FileInput';
import { useProfile } from '../../contexts/profileContext';
import { RoleEnum } from '../../contants/enum/RoleEnum';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';
import { useLoading } from '../../contexts/loadingContext';

interface IFormAccountability {
  id?: string;
  openingBalance: number;
  resourceReceived: number;
  incomeReceived: number;
  otherIncome: number;
  returnedAmount: number;
  previousAccountBalance: number;
  totalIncome: number;
  costExpense: number;
  investmentExpense: number;
  amountPaid: number;
  finalBalance: number;
  reschedulingBalance: number;
  reOpen: boolean;
  PCAForwardingOfficeAttachment: IFile[];
  executionWorksheetAttachment: IFile[];
  bankStatementsAttachment: IFile[];
}

interface IFile {
  fileName: string;
}

interface IParams {
  accountabilityId: string;
  accountabilityRequestsId: string;
}

const schemaAccountabilityForm = yup.object().shape({
  openingBalance: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  resourceReceived: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  incomeReceived: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  otherIncome: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  returnedAmount: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  previousAccountBalance: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  // totalIncome: yup
  //   .mixed()
  //   .transform((value) => formatCurrencyToNumber(value))
  //   .required('Campo obrigatório.'),
  costExpense: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  investmentExpense: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  // amountPaid: yup
  //   .mixed()
  //   .transform((value) => formatCurrencyToNumber(value))
  //   .required('Campo obrigatório.'),
  // finalBalance: yup
  //   .mixed()
  //   .transform((value) => formatCurrencyToNumber(value))
  //   .required('Campo obrigatório.'),
  reschedulingBalance: yup
    .mixed()
    .transform((value) => formatCurrencyToNumber(value))
    .required('Campo obrigatório.'),
  PCAForwardingOfficeAttachment: yup
    .mixed()
    .test('is-empty', 'Campo obrigatório.', (file) => !!file[0])
    .test(
      'is-pdf',
      'Permitido apenas PDF',
      (file) => file[0]?.type === 'application/pdf' || file[0]?.url
    ),
  executionWorksheetAttachment: yup
    .mixed()
    .test('is-empty', 'Campo obrigatório.', (file) => !!file[0])
    .test(
      'is-pdf',
      'Permitido apenas PDF',
      (file) => file[0]?.type === 'application/pdf' || file[0]?.url
    ),
  bankStatementsAttachment: yup
    .mixed()
    .test('is-empty', 'Campo obrigatório.', (file) => !!file[0])
    .test(
      'is-pdf',
      'Permitido apenas PDF',
      (file) => file[0]?.type === 'application/pdf' || file[0]?.url
    ),
});

function AccountabilityForm(): JSX.Element {
  const history = useHistory();
  const { enableButton, disableButton } = useCurrentCity();
  const { hasPermission } = useProfile();
  const { addToast } = useToast();
  const { openLoading, closeLoading } = useLoading();
  const { handleSubmit, control, watch, reset } = useForm<IFormAccountability>({
    resolver: yupResolver(schemaAccountabilityForm),
    reValidateMode: 'onSubmit',
  });

  const reOpen = watch('reOpen');
  const fieldsForTotalIncome = watch([
    'openingBalance',
    'resourceReceived',
    'incomeReceived',
    'otherIncome',
    'returnedAmount',
    'previousAccountBalance',
  ]);
  const fieldsForAmountPaid = watch(['costExpense', 'investmentExpense']);

  const params = useParams<IParams>();

  const [
    accountabilityRequest,
    setAccountabilityRequest,
  ] = useState<AccountabilityRequest>({} as AccountabilityRequest);

  const sumValues = useCallback((object: number[]) => {
    return Object.values(object).reduce((ac, val) => {
      const valToSum =
        typeof val === 'string' ? formatCurrencyToNumber(String(val)) : val;
      if (valToSum) {
        return ac + valToSum;
      }
      return ac;
    }, 0);
  }, []);

  const handleReOpenAccountability = useCallback(async () => {
    try {
      openLoading();
      await AccountabilityRequestService.reOpen({
        id: params.accountabilityId,
        reOpen: true,
      });
      addToast({
        message: 'Prestação de conta re-aberta.',
        type: 'success',
      });
      history.goBack();
    } catch (err) {
      addToast({
        message: 'Não foi possível re-abrir a prestação de conta.',
        type: 'error',
      });
    } finally {
      closeLoading();
    }
  }, [params, openLoading, closeLoading, addToast, history]);

  const totalIncome = useMemo(() => {
    const resultValue = sumValues(fieldsForTotalIncome) || 0;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(resultValue);
  }, [fieldsForTotalIncome, sumValues]);

  const amountPaid = useMemo(() => {
    const resultValue = sumValues(fieldsForAmountPaid) || 0;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(resultValue);
  }, [fieldsForAmountPaid, sumValues]);

  const finalBalance = useMemo(() => {
    const resultValue =
      Number(
        totalIncome
          .replaceAll('R$', '')
          .replaceAll('.', '')
          .replaceAll(',', '.')
      ) -
      Number(
        amountPaid.replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.')
      );
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(resultValue);
  }, [totalIncome, amountPaid]);

  const isReOpenVisible = useMemo(() => {
    if (
      hasPermission([RoleEnum.Administrador, RoleEnum.SEDH]) &&
      typeof reOpen === 'boolean' &&
      !reOpen
    )
      return true;

    return false;
  }, [hasPermission, reOpen]);

  const canEditAndSave = useMemo(() => {
    if (reOpen) return true;

    if (params.accountabilityId === 'new') return true;

    return false;
  }, [reOpen, params]);

  const onSubmit = useCallback(
    async (data: IFormAccountability) => {
      openLoading();
      try {
        if (params.accountabilityId === 'new') {
          await AccountabilityService.create({
            openingBalance: data.openingBalance,
            resourceReceived: data.resourceReceived,
            incomeReceived: data.incomeReceived,
            otherIncome: data.otherIncome,
            returnedAmount: data.returnedAmount,
            previousAccountBalance: data.previousAccountBalance,
            costExpense: data.costExpense,
            investmentExpense: data.investmentExpense,
            reschedulingBalance: data.reschedulingBalance,
            totalIncome: formatCurrencyToNumber(totalIncome),
            amountPaid: formatCurrencyToNumber(amountPaid),
            finalBalance: formatCurrencyToNumber(finalBalance),
            PCAForwardingOfficeAttachment: data.PCAForwardingOfficeAttachment,
            executionWorksheetAttachment: data.executionWorksheetAttachment,
            bankStatementsAttachment: data.bankStatementsAttachment,
            accountabilityRequestId: accountabilityRequest.id as string,
          });

          addToast({
            message: 'Prestação de conta enviada.',
            type: 'success',
          });
          history.goBack();
        } else {
          await AccountabilityService.update({
            id: params.accountabilityId,
            openingBalance: data.openingBalance,
            resourceReceived: data.resourceReceived,
            incomeReceived: data.incomeReceived,
            otherIncome: data.otherIncome,
            returnedAmount: data.returnedAmount,
            previousAccountBalance: data.previousAccountBalance,
            costExpense: data.costExpense,
            investmentExpense: data.investmentExpense,
            reschedulingBalance: data.reschedulingBalance,
            totalIncome: formatCurrencyToNumber(totalIncome),
            amountPaid: formatCurrencyToNumber(amountPaid),
            finalBalance: formatCurrencyToNumber(finalBalance),
            PCAForwardingOfficeAttachment: !data
              .PCAForwardingOfficeAttachment[0].fileName
              ? data.PCAForwardingOfficeAttachment
              : [],
            executionWorksheetAttachment: !data.executionWorksheetAttachment[0]
              .fileName
              ? data.executionWorksheetAttachment
              : [],
            bankStatementsAttachment: !data.bankStatementsAttachment[0].fileName
              ? data.bankStatementsAttachment
              : [],
            accountabilityRequestId: accountabilityRequest.id as string,
          });
          addToast({
            message: 'Prestação de conta atualizada.',
            type: 'success',
          });
          history.goBack();
        }
      } catch (error) {
        addToast({
          message: 'Não foi possível enviar a prestação de conta.',
          type: 'error',
        });
      }
      closeLoading();
    },
    [
      params,
      accountabilityRequest,
      history,
      totalIncome,
      amountPaid,
      finalBalance,
      addToast,
      openLoading,
      closeLoading,
    ]
  );

  useEffect(() => {
    enableButton();
    return () => {
      disableButton();
    };
  }, [enableButton, disableButton]);

  useEffect(() => {
    async function loadAccountabilityRequest() {
      openLoading();
      const data = await AccountabilityRequestService.getById(
        params.accountabilityRequestsId
      );
      setAccountabilityRequest(data);
      if (params.accountabilityId !== 'new') {
        try {
          const form = await AccountabilityService.getById(
            params.accountabilityId
          );

          reset({
            openingBalance: form.openingBalance,
            resourceReceived: form.resourceReceived,
            incomeReceived: form.incomeReceived,
            otherIncome: form.otherIncome,
            returnedAmount: form.returnedAmount,
            previousAccountBalance: form.previousAccountBalance,
            totalIncome: form.totalIncome,
            costExpense: form.costExpense,
            investmentExpense: form.investmentExpense,
            amountPaid: form.amountPaid,
            finalBalance: form.finalBalance,
            reschedulingBalance: form.reschedulingBalance,
            reOpen: form.reOpen,
            PCAForwardingOfficeAttachment: [form.PCAForwardingOfficeAttachment],
            executionWorksheetAttachment: [form.executionWorksheetAttachment],
            bankStatementsAttachment: [form.bankStatementsAttachment],
          });
        } catch (error) {
          addToast({
            message: 'Não foi possível carregar a prestação de conta.',
            type: 'error',
          });
          history.goBack();
        }
      }
      closeLoading();
    }
    loadAccountabilityRequest();
  }, [params, reset, openLoading, closeLoading, addToast, history]);

  return (
    <Container>
      <BankAccountWrapper>
        <h2>
          Referente a conta bancária: {accountabilityRequest.bankAccount?.name}
        </h2>
        <p>Conta: {accountabilityRequest.bankAccount?.account}</p>
        <p>Agencia: {accountabilityRequest.bankAccount?.argency}</p>
      </BankAccountWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>
          Receita disponível em {accountabilityRequest.accountabilityType?.name}
          .
        </h2>
        <Input
          control={control}
          name="openingBalance"
          label={`Saldo inicial da ${accountabilityRequest.bankAccount?.accountType?.name.toLocaleLowerCase()} em 01 de janeiro de ${
            accountabilityRequest?.year
          }.`}
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="resourceReceived"
          label="Valor recebido de cofinanciamento estadual."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="incomeReceived"
          label="Rendimentos bancários do exercício."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="otherIncome"
          label="Outros egressos de recursos/rendimentos."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="returnedAmount"
          label="Saldo devolvido para conta do fundo municipal."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="previousAccountBalance"
          label="Saldo de Contas Anteriores."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="totalIncome"
          value={totalIncome}
          label="Total recebido."
          disabled
          currency
        />
        <h2>
          Execução dos recursos em{' '}
          {accountabilityRequest.accountabilityType?.name}.
        </h2>
        <Input
          control={control}
          name="costExpense"
          label="Despesa de custeio."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="investmentExpense"
          label="Despesas com material permanente/investimento."
          disabled={!canEditAndSave}
          currency
        />
        <Input
          control={control}
          name="amountPaid"
          value={amountPaid}
          disabled
          label="Valor pago."
        />
        <h2>
          Reprogramação em {accountabilityRequest.accountabilityType?.name}.
        </h2>
        <Input
          control={control}
          name="finalBalance"
          label={`Saldo final em 31 de dezembro de ${accountabilityRequest?.year}.`}
          value={finalBalance}
          disabled
        />
        <Input
          control={control}
          name="reschedulingBalance"
          label="Saldo reprogramado."
          disabled={!canEditAndSave}
          currency
        />
        <FileInput
          control={control}
          name="PCAForwardingOfficeAttachment"
          label="Anexo do ofício de encaminhamento da PCA; Resolução de aprovação da PCA por parte do Conselho e reprogramação dos recursos."
          disabled={!canEditAndSave}
        />
        <FileInput
          control={control}
          name="executionWorksheetAttachment"
          label="Anexo de planilha de execução financeira."
          disabled={!canEditAndSave}
        />
        <FileInput
          control={control}
          name="bankStatementsAttachment"
          label="Anexo dos extratos bancários da conta-corrente e aplicação de todo o exercício."
          disabled={!canEditAndSave}
        />

        {canEditAndSave && <Button type="submit" text="SALVAR" />}
        {isReOpenVisible && (
          <Button
            type="button"
            onClick={handleReOpenAccountability}
            text="Re-abrir prestação"
          />
        )}

        <DownloadsWrapper>
          <h3>Downloads de auxilio:</h3>
          <ul>
            <li>
              <a
                href={`${process.env.REACT_APP_API_URL}/files/PLANILHA-ANEXOS-COFINANCIAMENTO.xlsx`}
              >
                Planilha de anexos cofianciamento
              </a>
            </li>
          </ul>
        </DownloadsWrapper>
      </form>
    </Container>
  );
}

export default AccountabilityForm;
