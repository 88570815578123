import styled, { css } from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex: 1;
    flex-direction: column;

    button {
      align-self: flex-end;
    }
  }
`;

export const BankAccountWrapper = styled.div`
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.border};
  `}
`;

export const ResourcesWrapper = styled.div``;

export const ResourceItem = styled.div`
  padding: 8px;
  margin: 8px 0;

  border-radius: 8px;
  border: 1px solid lightgray;
`;

export const DownloadsWrapper = styled.div`
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.border};
  `}

  li {
    margin-left: 21px;
  }
`;
