import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Container } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import * as UserService from '../../services/UserService';
import * as RoleService from '../../services/RoleService';

import Role from '../../models/Role';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';
import { useLoading } from '../../contexts/loadingContext';

interface IFormUser {
  id?: string;
  name: string;
  email: string;
  stateId: number;
  cityId: number;
  roleId: number;
}

interface IParams {
  id: string;
}

const schemaUser = yup.object().shape({
  id: yup.string().uuid(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  roleId: yup.number().required(),
});

function UsersForm(): JSX.Element {
  const { currentCity, enableButton, disableButton } = useCurrentCity();
  const { addToast } = useToast();
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const params = useParams<IParams>();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaUser),
    reValidateMode: 'onSubmit',
  });

  const [roles, setRoles] = useState<Role[]>([]);

  const loadUser = useCallback(async () => {
    if (params.id !== 'new') {
      try {
        const {
          id,
          name,
          cityId,
          city,
          email,
          roleId,
        } = await UserService.getById(params.id);

        reset({
          id,
          name,
          email,
          stateId: city?.stateId,
          cityId,
          roleId,
        });
      } catch (error) {
        addToast({
          message: 'Não foi possível carregar o usuário.',
          type: 'error',
        });
        history.goBack();
      }
    }
  }, [params, reset, addToast, history]);

  const loadRoles = useCallback(async () => {
    try {
      setRoles(await RoleService.getAll());
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar as permissões.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history]);

  const onSubmit = useCallback(
    async ({ name, email, roleId }: IFormUser) => {
      openLoading();
      if (params.id !== 'new') {
        try {
          await UserService.update({
            id: params.id,
            name,
            email,
            cityId: currentCity?.id as number,
            roleId,
          });
          addToast({
            message: 'Usuário atualizado.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message: 'Não foi possível atualizar o usuário',
            type: 'error',
          });
        }
      } else {
        try {
          await UserService.create({
            name,
            email,
            cityId: currentCity?.id as number,
            roleId,
          });
          addToast({
            message: 'Usuário cadastrado.',
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          addToast({
            message: 'Não foi possível salvar o usuário',
            type: 'error',
          });
        }
      }
      closeLoading();
    },
    [params, history, currentCity, addToast, openLoading, closeLoading]
  );

  useEffect(() => {
    async function load() {
      openLoading();
      await loadRoles();
      await loadUser();
      closeLoading();
    }

    load();
  }, [loadRoles, loadUser, openLoading, closeLoading]);

  useEffect(() => {
    enableButton();
    return () => {
      disableButton();
    };
  }, [enableButton, disableButton]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input name="name" control={control} label="Nome" />
        <Input name="email" control={control} label="E-mail" />
        <Select
          name="roleId"
          control={control}
          label="Permissão"
          data={roles}
        />
        <Button type="submit" text="SALVAR" />
      </form>
    </Container>
  );
}

export { UsersForm };
