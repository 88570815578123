import { useCallback, useState, SelectHTMLAttributes, useEffect } from 'react';
import {
  Control,
  useController,
  FieldValue,
  FieldValues,
} from 'react-hook-form';

import { Container } from './styles';

interface IData {
  id?: string | number;
  name: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  control?: Control<FieldValue<FieldValues>>;
  label?: string;
  name: string;
  data?: IData[];
}

function Select({
  control,
  name,
  label,
  data,
  ...rest
}: SelectProps): JSX.Element {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, defaultValue: '' });
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isFocused={isFocused}>
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        {...rest}
      >
        <option value="">Selecione...</option>
        {data?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {error && <span>{error.message}</span>}
    </Container>
  );
}

Select.defaultProps = {
  label: 'Label',
  data: [],
  control: undefined,
};

export default Select;
