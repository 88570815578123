import { useCallback, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';
import {
  CitiesWrapper,
  CityHeader,
  CityInputWrapper,
  Container,
} from './styles';

import * as AccountabilityRequestService from '../../services/AccountabilityRequestService';
import AccountabilityType from '../../models/AccountabilityType';
import BankAccount from '../../models/BankAccount';
import * as BankAccountService from '../../services/BankAccountService';
import * as AccountabilityTypesService from '../../services/AccountabilityTypesService';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';
import { useLoading } from '../../contexts/loadingContext';
import { useStateCity } from '../../contexts/stateCityContext';
import City from '../../models/City';

interface IFormAccountabilityRequest {
  id?: string;
  year: number;
  batchAccountabilityRequest: IFormBetchAccountabilityRequest[];
  accountabilityTypeId: number;
}

interface IFormBetchAccountabilityRequest {
  cityId: number;
  bankAccountId: string;
  city: City;
  bankAccount: BankAccount;
}

const schemaAccountabilityRequestForm = yup.object().shape({
  id: yup.string().uuid(),
  year: yup
    .number()
    .typeError('Campo precisa ser um número.')
    .min(2000, "Campo precisa ser um número entre '2000' e '2050'.")
    .max(2050, "Campo precisa ser um número entre '2000' e '2050'.")
    .required('Campo obrigatório.'),
  accountabilityTypeId: yup.string().required('Campo obrigatório.'),
  batchAccountabilityRequest: yup.array().of(
    yup.object().shape({
      cityId: yup
        .number()
        .typeError('Campo obrigatório.')
        .required('Campo obrigatório.'),
      bankAccountId: yup.string().required('Campo obrigatório.'),
    })
  ),
});

function AccountabilityRequestsFormBetch(): JSX.Element {
  const history = useHistory();
  const { currentCity, enableButton, disableButton } = useCurrentCity();
  const { cities } = useStateCity();
  const { addToast } = useToast();
  const { openLoading, closeLoading } = useLoading();

  const {
    handleSubmit,
    control,
    getValues,
  } = useForm<IFormAccountabilityRequest>({
    resolver: yupResolver(schemaAccountabilityRequestForm),
  });
  const { fields, append, remove } = useFieldArray<IFormAccountabilityRequest>({
    control,
    name: 'batchAccountabilityRequest',
  });

  const [accountabilityTypes, setAccountabilityTypes] = useState<
    AccountabilityType[]
  >([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const onSubmit = useCallback(
    async ({
      year,
      batchAccountabilityRequest,
      accountabilityTypeId,
    }: IFormAccountabilityRequest) => {
      openLoading();
      try {
        await AccountabilityRequestService.createBatch({
          year,
          accountabilityTypeId,
          batchAccountabilityRequest: batchAccountabilityRequest.map(
            (item) => ({
              cityId: item.cityId,
              bankAccountId: item.bankAccountId,
            })
          ),
        });
        addToast({
          message: 'Solicitação de prestação de conta cadastrado.',
          type: 'success',
        });
        history.goBack();
      } catch (error) {
        addToast({
          message:
            'Não foi possível salvar a solicitação de prestação de conta',
          type: 'error',
        });
      }
      closeLoading();
    },
    [history, addToast, openLoading, closeLoading]
  );

  const loadAccountabilityTypes = useCallback(async () => {
    try {
      setAccountabilityTypes(await AccountabilityTypesService.getAll());
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar os tipos de prestação de conta.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history]);

  const loadBankAccounts = useCallback(async () => {
    try {
      setBankAccounts(
        (await BankAccountService.getAll({ pageSize: 9989 })).items
      );
    } catch (error) {
      addToast({
        message: 'Não foi possível carregar as contas bancárias.',
        type: 'error',
      });
      history.goBack();
    }
  }, [addToast, history]);

  const handleAddCities = useCallback(() => {
    openLoading();
    remove();

    const citiesInState = cities.filter(
      (city) => city.stateId === currentCity?.stateId
    );

    const accountabilityTypeId = getValues('accountabilityTypeId');
    const accountabilityType = accountabilityTypes.find(
      (accType) => accType.id === Number(accountabilityTypeId)
    );

    if (!accountabilityType) {
      addToast({
        message: 'Selecione o tipo da prestação de conta.',
        type: 'error',
      });
      closeLoading();
      return;
    }

    const asd = citiesInState.map((city) => {
      const bankAccount = bankAccounts.find(
        (bankAccountForFind) =>
          bankAccountForFind.cityId === city.id &&
          bankAccountForFind.name.includes(accountabilityType.name)
      );

      return {
        cityId: city.id,
        bankAccountId: bankAccount?.id,
        city,
        bankAccount,
      };
    });

    append(asd);
    closeLoading();
  }, [
    append,
    currentCity,
    cities,
    bankAccounts,
    getValues,
    addToast,
    accountabilityTypes,
    closeLoading,
    openLoading,
    remove,
  ]);

  useEffect(() => {
    enableButton();
    return () => {
      disableButton();
    };
  }, [enableButton, disableButton]);

  useEffect(() => {
    async function load() {
      openLoading();
      await loadAccountabilityTypes();
      await loadBankAccounts();
      closeLoading();
    }

    load();
  }, [loadAccountabilityTypes, loadBankAccounts, openLoading, closeLoading]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input name="year" control={control} label="Ano da prestação" />
        <Select
          name="accountabilityTypeId"
          control={control}
          label="Tipo da prestação de conta"
          data={accountabilityTypes}
        />
        <CitiesWrapper>
          <CityHeader>
            <h3>Cidades para prestação de contas</h3>
            <Button
              type="button"
              onClick={handleAddCities}
              text="Adicionar todas as cidades"
            />
          </CityHeader>
          {fields.map((item, index) => (
            <CityInputWrapper key={item.id}>
              <Input
                label="Cidade:"
                name={`batchAccountabilityRequest.${index}.city.name`}
                control={control}
                disabled
              />
              <Select
                name={`batchAccountabilityRequest.${index}.bankAccountId`}
                control={control}
                data={bankAccounts}
                label="Conta bancaria:"
              />
            </CityInputWrapper>
          ))}
        </CitiesWrapper>
        <Button type="submit" text="SALVAR" />
      </form>
    </Container>
  );
}

export { AccountabilityRequestsFormBetch };
