import { useCallback, useEffect, useMemo } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container, ButtonActionTable } from './styles';

import Button from '../../components/Button';

import AccountabilityRequest from '../../models/AccountabilityRequest';

import * as AccountabilityRequestService from '../../services/AccountabilityRequestService';
import { usePagination } from '../../hooks/usePagination';
import Table from '../../components/Table';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useToast } from '../../contexts/toastContext';

function AccountabilityRequestsList(): JSX.Element {
  const history = useHistory();
  const { addToast } = useToast();
  const { currentCity } = useCurrentCity();
  const params = useMemo(() => ({ cityId: currentCity?.id }), [currentCity]);
  const {
    items: accountabilityRequests,
    removeItem,
    error,
    ...paginationProps
  } = usePagination<AccountabilityRequest>({
    url: 'accountabilityRequests',
    pageSize: 10,
    params,
  });

  const handleNewAccountabilityRequest = useCallback(() => {
    history.push('/accountabilityRequests/new');
  }, [history]);

  const handleNewAccountabilityRequestBetch = useCallback(() => {
    history.push('/accountabilityRequests/betch/new');
  }, [history]);

  const handleDeleteAccountabilityRequest = useCallback(
    async (accountabilityRequestId: string | undefined) => {
      if (accountabilityRequestId) {
        try {
          await AccountabilityRequestService.remove(accountabilityRequestId);
          removeItem(accountabilityRequestId);
          addToast({
            message: 'Solicitação de prestação de conta deletado.',
            type: 'success',
          });
        } catch (err) {
          addToast({
            message:
              'Não foi possível deletar a solicitação de prestação de conta.',
            type: 'error',
          });
        }
      }
    },
    [removeItem, addToast]
  );

  const handleEditAccountabilityRequest = useCallback(
    (accountabilityRequestId: string | undefined) => {
      if (accountabilityRequestId) {
        history.push(`/accountabilityRequests/${accountabilityRequestId}`);
      }
    },
    [history]
  );

  useEffect(() => {
    if (error) addToast({ message: error, type: 'error' });
  }, [error, addToast]);

  return (
    <Container>
      <div>
        <Button
          onClick={handleNewAccountabilityRequest}
          text="NOVA PRESTAÇÂO"
        />
        <Button
          onClick={handleNewAccountabilityRequestBetch}
          text="PRESTAÇÂO EM LOTE"
        />
      </div>
      <Table pagination={paginationProps}>
        <thead>
          <tr>
            <th>Conta bancaria</th>
            <th>Tipo</th>
            <th>Ano</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {accountabilityRequests.map((accountabilityRequest) => (
            <tr key={accountabilityRequest.id}>
              <td>{accountabilityRequest.bankAccount?.name}</td>
              <td>{accountabilityRequest.accountabilityType?.name}</td>
              <td>{accountabilityRequest.year}</td>
              <td>
                <ButtonActionTable
                  onClick={() =>
                    handleEditAccountabilityRequest(accountabilityRequest.id)
                  }
                >
                  <MdEdit />
                </ButtonActionTable>
                <ButtonActionTable
                  onClick={() =>
                    handleDeleteAccountabilityRequest(accountabilityRequest.id)
                  }
                >
                  <MdDelete />
                </ButtonActionTable>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export { AccountabilityRequestsList };
