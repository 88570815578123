import {
  ButtonHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ButtonWrapper, Container } from './styles';

interface DropdownProps {
  button: ReactNode;
  children: ReactNode;
}
interface DropdownItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

function Dropdown({ button, children }: DropdownProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleCloseModal);

    return () => {
      document.removeEventListener('mouseup', handleCloseModal);
    };
  }, [handleCloseModal]);

  return (
    <>
      <ButtonWrapper onClick={handleOpenModal}>{button}</ButtonWrapper>
      <Container isVisible={isVisible} onMouseLeave={handleCloseModal}>
        {children}
      </Container>
    </>
  );
}

Dropdown.Item = ({ children, ...props }: DropdownItemProps) => (
  <button type="button" {...props}>
    <li>{children}</li>
  </button>
);

export { Dropdown };
