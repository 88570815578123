import BankAccount from '../models/BankAccount';
import IPaginationDTO from '../dto/IPaginationDTO';
import api from './api';

interface IBankAccountParams {
  cityId?: number;
  pageSize?: number;
}

export async function getAll(
  params: IBankAccountParams
): Promise<IPaginationDTO<BankAccount>> {
  const response = await api.get<IPaginationDTO<BankAccount>>('bankAccounts', {
    params: { ...params },
  });

  return response.data;
}

export async function getById(bankAccountId: string): Promise<BankAccount> {
  const response = await api.get<BankAccount>(`bankAccounts/${bankAccountId}`);

  return response.data;
}

export async function create(bankAccount: BankAccount): Promise<void> {
  await api.post(`bankAccounts`, bankAccount);
}

export async function update(bankAccount: BankAccount): Promise<void> {
  await api.put(`bankAccounts`, bankAccount);
}

export async function remove(bankAccountId: string): Promise<void> {
  await api.delete(`bankAccounts/${bankAccountId}`);
}
