import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

interface IAxiosRequestConfig extends AxiosRequestConfig {
  isRetry: boolean;
}

interface IAxiosErrorWithOriginalRequest extends AxiosError {
  config: IAxiosRequestConfig;
}

let interceptorId = -1;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.registerInterceptWithAuthContext = ({
  signOut,
  tokenRefresh,
  setTokens,
}) => {
  interceptorId = api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: IAxiosErrorWithOriginalRequest) => {
      const originalRequest = error.config;

      if (!error.response) return Promise.reject(error);

      if (
        error.response.status === 401 &&
        originalRequest.url === '/sessions/refresh'
      ) {
        signOut();
        return Promise.reject(error);
      }
      if (
        error.response.status === 401 &&
        originalRequest.url === '/sessions'
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest.isRetry) {
        originalRequest.isRetry = true;

        return (
          api
            .post('/sessions/refresh', {
              tokenRefresh,
            })
            // eslint-disable-next-line consistent-return
            .then(async (res) => {
              if (res.status === 200) {
                setTokens({
                  token: res.data.token,
                  tokenRefresh: res.data.tokenRefresh,
                });

                originalRequest.headers.Authorization = `Bearer ${res.data.token}`;

                return axios(originalRequest);
              }
            })
        );
      }
      return Promise.reject(error);
    }
  );
};

api.unRegisterInterceptWithAuthContext = () => {
  api.interceptors.response.eject(interceptorId);
};

export default api;
