import { ButtonHTMLAttributes, useCallback, useState } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

function Button({ text, ...rest }: ButtonProps): JSX.Element {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container
      isFocused={isFocused}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      {...rest}
    >
      {text}
    </Container>
  );
}

export default Button;
