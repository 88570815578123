import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from 'react';
import { ProfileModal } from '../components/ProfileModal';
import { RoleEnum } from '../contants/enum/RoleEnum';
import User from '../models/User';

interface ProfileContextProps {
  children: ReactNode;
}

export interface ProfileContextData {
  profile?: User;
  openProfileModal: () => void;
  setProfile: (profile?: User) => void;
  hasPermission: (roles: RoleEnum[]) => boolean;
}

const ProfileContext = createContext<ProfileContextData>(
  {} as ProfileContextData
);

function ProfileProvider({ children }: ProfileContextProps): JSX.Element {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [profile, setNewProfile] = useState<User | undefined>(() => {
    const profileStorage = localStorage.getItem('@user/PROFILE');
    if (profileStorage) {
      return JSON.parse(profileStorage);
    }
    return undefined;
  });

  const setProfile = useCallback((newProfile?: User) => {
    if (newProfile) {
      localStorage.setItem('@user/PROFILE', JSON.stringify(newProfile));
    } else {
      localStorage.removeItem('@user/PROFILE');
    }

    setNewProfile(newProfile);
  }, []);

  const hasPermission = useCallback(
    (roles: RoleEnum[]): boolean => {
      if (!profile) return false;

      return roles.includes(profile.roleId);
    },
    [profile]
  );

  const openProfileModal = useCallback(() => {
    setModalIsVisible(true);
  }, []);

  const closeProfileModal = useCallback(() => {
    setModalIsVisible(false);
  }, []);

  return (
    <ProfileContext.Provider
      value={{ profile, openProfileModal, setProfile, hasPermission }}
    >
      {children}
      <ProfileModal isVisible={modalIsVisible} closeModal={closeProfileModal} />
    </ProfileContext.Provider>
  );
}

function useProfile(): ProfileContextData {
  const context = useContext(ProfileContext);

  return context;
}

export { ProfileProvider, useProfile };
