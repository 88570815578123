import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
}

export const Container = styled.button<ContainerProps>`
  height: 40px;
  border: none;

  border-radius: 8px;

  margin: 8px 0;
  padding: 0 8px;

  border: 1px solid white;

  transition: opacity 0.1s;

  ${({ theme }) =>
    css`
      background: ${theme.colors.secondary};
      color: ${theme.colors.textWhite};
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}

  @media (max-width: 650px) {
    width: 100%;
  }

  :hover {
    opacity: 0.8;

    transition: opacity 0.1s;
  }
`;
