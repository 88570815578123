import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import Loading from '../components/Loading';

interface LoadingContextProps {
  children: ReactNode;
}

export interface LoadingContextData {
  openLoading: (message?: string) => void;
  closeLoading: () => void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData
);

function LoadingProvider({ children }: LoadingContextProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('Carregando...');

  const openLoading = useCallback((newMessage) => {
    setMessage(newMessage || 'Carregando...');
    setIsLoading(true);
  }, []);

  const closeLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        openLoading,
        closeLoading,
      }}
    >
      {children}
      <Loading isLoading={isLoading} message={message} />
    </LoadingContext.Provider>
  );
}

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  return context;
}

export { LoadingProvider, useLoading };
