import { useMemo } from 'react';
import Table from '../../components/Table';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { usePagination } from '../../hooks/usePagination';
import Auditing from '../../models/Auditing';
import { Container } from './styles';

function AuditingList(): JSX.Element {
  const { currentCity } = useCurrentCity();
  const params = useMemo(() => ({ cityId: currentCity?.id }), [currentCity]);
  const { items, ...paginationProps } = usePagination<Auditing>({
    url: '/auditing',
    initialPage: 1,
    pageSize: 10,
    params,
  });

  return (
    <Container>
      <Table pagination={paginationProps}>
        <thead>
          <tr>
            <th>Ação</th>
            <th>Usuario</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr>
              <td>
                {item.method} - {item.module}
              </td>
              <td>{item.user?.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export { AuditingList };
