import api from './api';

interface ISendResetEmail {
  email: string;
}

interface IChangePasswordWithToken {
  token: string;
  password: string;
  confirmPassword: string;
}

export async function sendResetEmail({
  email,
}: ISendResetEmail): Promise<void> {
  await api.post('/reset', { email });
}

export async function changePasswordWithToken({
  token,
  password,
  confirmPassword,
}: IChangePasswordWithToken): Promise<void> {
  await api.put(`/reset/${token}`, { password, confirmPassword });
}
