import {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Control,
  FieldValue,
  FieldValues,
  useController,
} from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import { Container, InputWrapper } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  control?: Control<FieldValue<FieldValues>>;
  label?: string;
  name: string;
  mask?: string | (string | RegExp)[];
  currency?: boolean;
  showIconForPassword?: boolean;
}

function Input({
  control,
  label,
  name,
  mask,
  currency,
  type,
  showIconForPassword,
  ...rest
}: InputProps): JSX.Element {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  const [isFocused, setIsFocused] = useState(false);
  const [showValue, setShowValue] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onChangeInput = useCallback(
    (e) => {
      if (type && type === 'file') {
        onChange(e.target.files);
      } else {
        onChange(e.target.value);
      }
    },
    [type, onChange]
  );

  const handleToggleShowValue = useCallback(() => {
    setShowValue((oldState) => !oldState);
  }, []);

  const ToggleShowValue = useMemo(
    () => (
      <>
        {type === 'password' && (
          <>
            {showValue ? (
              <IoMdEyeOff onClick={handleToggleShowValue} size={26} />
            ) : (
              <IoMdEye onClick={handleToggleShowValue} size={26} />
            )}
          </>
        )}
      </>
    ),
    [handleToggleShowValue, showValue, type]
  );

  const currencyMask = useMemo(() => {
    if (currency) {
      return createNumberMask({
        prefix: 'R$ ',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
      });
    }
    return mask;
  }, [currency, mask]);

  const typeAttribute = useMemo(() => {
    if (type !== 'password') return type;

    if (showValue) {
      return 'text';
    }

    return type;
  }, [type, showValue]);

  useEffect(() => {
    if (typeof value === 'number' && currency) {
      const valueFormated = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
      onChange(valueFormated);
    }
  }, [value, onChange, currency]);

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <InputWrapper isFocused={isFocused}>
        {mask || currency ? (
          <MaskedInput
            name={name}
            onChange={onChangeInput}
            value={value}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            mask={currencyMask}
            autoComplete="off"
            type={typeAttribute}
            {...rest}
          />
        ) : (
          <>
            <input
              name={name}
              onChange={onChangeInput}
              value={value}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoComplete="off"
              type={typeAttribute}
              {...rest}
            />
            {ToggleShowValue}
          </>
        )}
      </InputWrapper>

      {error && <span>{error.message}</span>}
    </Container>
  );
}

Input.defaultProps = {
  label: 'Label',
  mask: undefined,
  currency: false,
  control: undefined,
  showIconForPassword: false,
};

export default Input;
