import { useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { validate } from 'uuid';

import { Container } from './styles';

interface IBreadcrumbObject {
  pathName: string;
  name: string;
}

const PageToBreadcrumMap: { [key: string]: string } = {
  dashboard: '',
  reset: 'reset',
  users: 'Usuarios',
  bankAccounts: 'Contas bancaria',
  accountabilityRequests: 'Solicitações de prestações de conta',
  accountability: 'Prestações de conta',
  auditing: 'Audiencia',
  new: 'Criar',
  uuid: 'Editar',
};

export function Breadcrumb(): JSX.Element {
  const { pathname } = useLocation();
  const [breadcrumbList, setBreadcrumbList] = useState<IBreadcrumbObject[]>([]);

  const mapPageToBreadcrumb = useCallback((name: string) => {
    const isUuid = validate(name);
    if (isUuid)
      return {
        pathName: name,
        name: PageToBreadcrumMap.uuid,
      };

    return {
      pathName: name,
      name: PageToBreadcrumMap[name],
    };
  }, []);

  useEffect(() => {
    const pathNameList = pathname
      .split('/')
      .map((name) => mapPageToBreadcrumb(name))
      .filter(({ name }) => !!name);

    if (pathNameList.some(({ name }) => name === 'Prestações de conta')) {
      setBreadcrumbList(pathNameList.slice(2, 4));
      return;
    }

    if (pathNameList.some(({ name }) => name === 'reset')) {
      setBreadcrumbList([]);
      return;
    }

    setBreadcrumbList(pathNameList);
  }, [mapPageToBreadcrumb, pathname]);

  return (
    <Container>
      <p>{breadcrumbList.map(({ name }) => name).join(' / ')}</p>
    </Container>
  );
}
