import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Container } from './styles';

import Button from '../../components/Button';
import Input from '../../components/Input';

import * as ResetPasswordService from '../../services/ResetPasswordService';

import { useToast } from '../../contexts/toastContext';

interface IForm {
  email: string;
}

const schemaSendResetPassword = yup.object().shape({
  email: yup.string().email('E-mail invalido').required('Campo obrigatório'),
});

function SendResetPassword(): JSX.Element {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schemaSendResetPassword),
  });
  const { addToast } = useToast();
  const history = useHistory();

  const onSubmit = useCallback(
    async ({ email }: IForm) => {
      try {
        await ResetPasswordService.sendResetEmail({ email });
        addToast({
          message: 'E-mail de redefinição enviado.',
          type: 'success',
        });
        history.goBack();
      } catch (err) {
        addToast({
          title: 'Não foi possivel enviar e-mail',
          message: 'Verifique se digitou corretamente.',
          type: 'error',
        });
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <h2>Deseja recuperar sua senha?</h2>
      <p>Informe seu e-mail e receba um link para a redefinição.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="E-mail" name="email" control={control} />
        <Button text="ENVIAR" type="submit" />
      </form>
    </Container>
  );
}

export { SendResetPassword };
