import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RoleEnum } from '../contants/enum/RoleEnum';
import { useProfile } from './profileContext';

import City from '../models/City';
import State from '../models/State';
import * as CityService from '../services/CityService';
import * as StateService from '../services/StateService';

interface StateCityContextProps {
  children: ReactNode;
}

export interface StateCityContextData {
  cities: City[];
  states: State[];
  statesByPermission: State[];
}

const StateCityContext = createContext<StateCityContextData>(
  {} as StateCityContextData
);

function StateCityProvider({ children }: StateCityContextProps): JSX.Element {
  const { profile, hasPermission } = useProfile();
  const [cities, setCities] = useState<City[]>([]);
  const [states, setStates] = useState<State[]>([]);

  const loadCities = useCallback(async () => {
    const res = await CityService.getAll();
    setCities(res);
  }, []);

  const loadStates = useCallback(async () => {
    const res = await StateService.getAll();
    setStates(res);
  }, []);

  const statesByPermission = useMemo(() => {
    if (!profile) return [];

    if (hasPermission([RoleEnum.SEDH])) {
      return states.filter((state) => state.id === profile.city?.stateId);
    }
    return states;
  }, [states, profile, hasPermission]);

  useEffect(() => {
    loadCities();
    loadStates();
  }, [loadCities, loadStates]);

  return (
    <StateCityContext.Provider value={{ cities, states, statesByPermission }}>
      {children}
    </StateCityContext.Provider>
  );
}

function useStateCity(): StateCityContextData {
  const context = useContext(StateCityContext);

  return context;
}

export { StateCityProvider, useStateCity };
