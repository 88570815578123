import User from '../models/User';
import IPagination from '../dto/IPaginationDTO';
import api from './api';

interface IChangePassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export async function getAll(): Promise<IPagination<User>> {
  const response = await api.get<IPagination<User>>('users');

  return response.data;
}

export async function getById(userId: string): Promise<User> {
  const response = await api.get<User>(`users/${userId}`);

  return response.data;
}

export async function create(user: User): Promise<void> {
  await api.post(`users`, user);
}

export async function update(user: User): Promise<void> {
  await api.put('users', user);
}

export async function remove(userId: string): Promise<void> {
  await api.delete(`users/${userId}`);
}

export async function changePassword({
  oldPassword,
  password,
  confirmPassword,
}: IChangePassword): Promise<void> {
  await api.put('/password', { oldPassword, password, confirmPassword });
}
