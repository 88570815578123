import AccountabilityType from '../models/AccountabilityType';
import api from './api';

export async function getAll(): Promise<AccountabilityType[]> {
  const response = await api.get<AccountabilityType[]>('accountabilityTypes');

  return response.data;
}

export async function getById(
  accountabilityTypeId: string
): Promise<AccountabilityType> {
  const response = await api.get<AccountabilityType>(
    `accountabilityTypes/${accountabilityTypeId}`
  );

  return response.data;
}
