import styled from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex: 1;
    flex-direction: column;

    align-items: flex-end;
  }
`;
