import { useCallback, useEffect, useState } from 'react';

import IPaginationDTO from '../dto/IPaginationDTO';
import Base from '../models/Base';
import api from '../services/api';

interface IParams {
  [key: string]: string | number | undefined;
}

interface PaginationProps {
  url: string;
  pageSize?: number;
  initialPage?: number;
  params?: IParams;
}

interface IReturn<T extends Base> {
  isLoading: boolean;
  error: string;
  items: T[];
  page: number;
  totalPage: number;
  goPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  nextPageDisabled: boolean;
  previousPageDisabled: boolean;
  removeItem: (id: string) => void;
}

function usePagination<T extends Base>({
  url,
  params = {},
  initialPage = 1,
  pageSize = 20,
}: PaginationProps): IReturn<T> {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [items, setItems] = useState<T[]>([]);
  const [page, setPage] = useState(initialPage);
  const [totalPage, setTotalPage] = useState(1);
  const [nextPageDisabled, setNextPageDisabled] = useState(false);
  const [previousPageDisabled, setPreviousNextPageDisabled] = useState(false);

  const nextPage = useCallback(() => {
    setPage((oldState) => oldState + 1);
  }, []);

  const previousPage = useCallback(() => {
    setPage((oldState) => oldState - 1);
  }, []);

  const goPage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const load = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get<IPaginationDTO<T>>(url, {
        params: { page, pageSize, ...params },
      });
      const newTotalPage = Math.ceil(data.total / pageSize);
      setTotalPage(newTotalPage);
      setItems(data.items);
      setNextPageDisabled(page === newTotalPage);
      setPreviousNextPageDisabled(page === 1);
    } catch (err) {
      setError('Não foi possivel carregar a lista.');
    } finally {
      setIsLoading(false);
    }
  }, [url, params, page, pageSize]);

  const removeItem = useCallback((id: string) => {
    setItems((oldState) => oldState.filter((item) => item.id !== id));
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return {
    isLoading,
    error,
    items,
    page,
    totalPage,
    goPage,
    nextPage,
    previousPage,
    nextPageDisabled,
    previousPageDisabled,
    removeItem,
  };
}

export { usePagination };
