import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    margin: 16px 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;

  justify-content: center;

  svg {
    animation: spin 2s linear infinite;
    font-size: 5rem;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
