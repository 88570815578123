import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;

  flex-direction: column;

  width: 100%;

  margin: 8px 0;

  label {
    margin-bottom: 4px;
  }

  select {
    flex: 1;

    height: 40px;

    border: 0px;
    border-radius: 8px;
    padding: 8px 8px;
    border: 1px solid white;

    ${({ theme }) =>
      css`
        border: 2px solid ${theme.colors.border};
      `}
    ${({ theme, isFocused }) =>
      isFocused &&
      css`
        border: 2px solid ${theme.colors.primary};
      `}
  }

  span {
    font-size: 0.75rem;
    margin-top: 4px;

    ${({ theme }) =>
      css`
        color: ${theme.colors.error};
      `}
  }
`;
