import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useProfile } from '../../contexts/profileContext';
import { useToast } from '../../contexts/toastContext';
import * as UserService from '../../services/UserService';
import Button from '../Button';
import Input from '../Input';
import { Modal } from '../Modal';
import { Divider } from './styles';

interface IProps {
  isVisible: boolean;
  closeModal: () => void;
}

interface IFormChangePassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const schemaChangePassword = yup.object().shape({
  oldPassword: yup.string().required('Campo obrigatório.'),
  password: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Senha deve conter caractere especial, letra maiúscula e minuscula e no mínimo 8 caracteres.'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'As senhas não conferem')
    .required('Campo obrigatório.'),
});

export function ProfileModal({ isVisible, closeModal }: IProps): JSX.Element {
  const { profile } = useProfile();
  const { addToast } = useToast();
  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(schemaChangePassword),
  });

  const onSubmit = useCallback(
    async ({ oldPassword, password, confirmPassword }: IFormChangePassword) => {
      try {
        await UserService.changePassword({
          oldPassword,
          password,
          confirmPassword,
        });
        addToast({
          title: 'Sucesso!',
          message: 'Senha alterada.',
          type: 'success',
        });
      } catch (err) {
        addToast({
          title: 'Falha',
          message: 'Não foi possivel alterar a senha',
          type: 'error',
        });
      }
    },
    [addToast]
  );

  useEffect(() => {
    reset({
      name: profile?.name,
      email: profile?.email,
      roleName: profile?.role?.name,
    });
  }, [profile, reset]);

  const handleCloseModal = useCallback(() => {
    reset();
    closeModal();
  }, [closeModal, reset]);

  return (
    <Modal isOpen={isVisible} title="Perfil" onRequestClose={handleCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name="name" label="Nome" disabled />
        <Input control={control} name="email" label="E-mail" disabled />
        <Input control={control} name="roleName" label="Cargo" disabled />
        <Divider />
        <h3>Alterar senha</h3>
        <Input
          control={control}
          name="oldPassword"
          type="password"
          label="Senha atual"
        />
        <Input
          control={control}
          name="password"
          type="password"
          label="Nova senha"
        />
        <Input
          control={control}
          name="confirmPassword"
          type="password"
          label="Confirmar nova senha"
        />
        <Button text="Salvar" />
      </form>
    </Modal>
  );
}
