import IPaginationDTO from '../dto/IPaginationDTO';
import AccountabilityRequest from '../models/AccountabilityRequest';
import api from './api';

interface IPatchRequest {
  id: string;
  reOpen: boolean;
}

interface IAccountabilityRequest {
  year: number;
  batchAccountabilityRequest: IBatchAccountabilityRequest[];
  accountabilityTypeId: number;
}

interface IBatchAccountabilityRequest {
  cityId: number;
  bankAccountId: string;
}

export async function getAll({
  cityId,
}: {
  cityId: number;
}): Promise<IPaginationDTO<AccountabilityRequest>> {
  const response = await api.get<IPaginationDTO<AccountabilityRequest>>(
    'accountabilityRequests',
    {
      params: { cityId },
    }
  );

  return response.data;
}

export async function getById(
  accountabilityRequestId: string
): Promise<AccountabilityRequest> {
  const response = await api.get<AccountabilityRequest>(
    `accountabilityRequests/${accountabilityRequestId}`
  );

  return response.data;
}

export async function create(
  accountabilityRequest: AccountabilityRequest
): Promise<void> {
  await api.post(`accountabilityRequests`, accountabilityRequest);
}

export async function createBatch(
  accountabilityRequest: IAccountabilityRequest
): Promise<void> {
  await api.post(`accountabilityRequests/batch`, accountabilityRequest);
}

export async function update(
  accountabilityRequest: AccountabilityRequest
): Promise<void> {
  await api.put(`accountabilityRequests`, accountabilityRequest);
}

export async function remove(accountabilityRequestId: string): Promise<void> {
  await api.delete<AccountabilityRequest>(
    `accountabilityRequests/${accountabilityRequestId}`
  );
}

export async function reOpen(request: IPatchRequest): Promise<void> {
  await api.patch(`/accountability/${request.id}/reopen`, {
    reOpen: request.reOpen,
  });
}
