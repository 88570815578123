import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;

  margin: 8px 0;

  label {
    margin-bottom: 4px;
  }

  input {
    display: none;
  }

  span {
    font-size: 0.75rem;
    margin-top: 4px;

    ${({ theme }) =>
      css`
        color: ${theme.colors.error};
      `}
  }
`;

export const FileContainer = styled.div`
  display: flex;
  width: 100%;

  background: none;

  min-height: 80px;

  border: 0px;
  border-radius: 8px;

  ${({ theme }) =>
    css`
      border: 2px solid ${theme.colors.border};
    `}

  p {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: gray;

    span {
      font-size: 1rem;
      color: black;
    }
  }
`;

export const ButtonViewFile = styled.button`
  display: flex;
  flex: 1;

  width: 100%;

  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  background: none;

  border: 0px;
`;
