import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useProfile } from './profileContext';
import User from '../models/User';
import api from '../services/api';

export interface IResponseSessions {
  token: string;
  tokenRefresh: string;
  user: User;
}

interface ITokens {
  token: string;
  tokenRefresh: string;
}

interface AuthContextProps {
  children: ReactNode;
}

export interface AuthContextData {
  token: string;
  tokenRefresh: string;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: AuthContextProps): JSX.Element {
  const { setProfile } = useProfile();

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@auth/TOKEN');
    if (tokenStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenStorage}`;
      return tokenStorage;
    }
    return '';
  });

  const [tokenRefresh, setTokenRefresh] = useState(() => {
    const tokenRefreshStorage = localStorage.getItem('@auth/TOKEN_REFRESH');

    if (tokenRefreshStorage) {
      return tokenRefreshStorage;
    }
    return '';
  });

  const setTokens = useCallback((tokens: ITokens) => {
    setToken(tokens.token);
    setTokenRefresh(tokens.tokenRefresh);
  }, []);

  const signOut = useCallback(() => {
    api.defaults.headers.Authorization = '';
    localStorage.clear();
    setToken('');
    setTokenRefresh('');
    setProfile(undefined);
  }, [setProfile]);

  const signIn = useCallback(
    async (email, password) => {
      const { data } = await api.post<IResponseSessions>('/sessions', {
        email,
        password,
      });

      setProfile(data.user);

      if (data.token) {
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
      }

      setTokens({
        token: data.token,
        tokenRefresh: data.tokenRefresh,
      });
    },
    [setProfile, setTokens]
  );

  useEffect(() => {
    if (token && tokenRefresh) {
      localStorage.setItem('@auth/TOKEN', token);
      localStorage.setItem('@auth/TOKEN_REFRESH', tokenRefresh);

      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
      }
      api.registerInterceptWithAuthContext({
        setTokens,
        signOut,
        tokenRefresh,
      });
    }

    return () => {
      api.unRegisterInterceptWithAuthContext();
    };
  }, [token, setTokens, signOut, tokenRefresh]);

  return (
    <AuthContext.Provider value={{ token, tokenRefresh, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
