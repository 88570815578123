import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Contexts } from './contexts';

import MainContainer from './components/MainContainer';

import GlobalStyle from './styles/global';
import Routes from './routes';

const history = createBrowserHistory();

function App(): JSX.Element {
  return (
    <Router history={history}>
      <Contexts>
        <MainContainer>
          <Routes />
        </MainContainer>
      </Contexts>

      <GlobalStyle />
    </Router>
  );
}

export default App;
