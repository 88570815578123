export default {
  title: 'main',

  colors: {
    primary: '#CB4A4A',
    secondary: '#327AE8',

    background: '#f7f7f7',
    border: '#d9d9d9',

    textWhite: '#fff',
    textBlack: '#000',

    success: '#4CAF50',
    error: '#f44336',
    info: '#2196F3',
    warning: '#FF9800',
  },
};
