import ReactModal from 'react-modal';
import styled from 'styled-components';

export const ModalWrapper = styled(ReactModal)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);

  @media (max-width: 752px) {
    align-items: flex-end;
  }
`;

export const Container = styled.div`
  background: white;

  max-height: 75%;
  max-width: 720px;
  overflow-y: auto;

  @media (min-width: 752px) {
    align-self: center;
    width: 720px;
    max-height: 85%;
  }

  flex: 1;

  padding: 16px;

  border-radius: 8px;

  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);

  .modal-header {
  }

  .modal-footer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;

  h2 {
    flex: 1;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
`;
