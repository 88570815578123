import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';

import { Container } from './styles';
import { useAuth } from '../../contexts/authContext';
import { useToast } from '../../contexts/toastContext';

interface IFormInputs {
  email: string;
  password: string;
}

const schemaLogin = yup.object().shape({
  email: yup.string().email('E-mail invalido').required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
});

function Login(): JSX.Element {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const { control, handleSubmit } = useForm<IFormInputs>({
    resolver: yupResolver(schemaLogin),
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async ({ email, password }: IFormInputs) => {
    try {
      await signIn(email, password);
    } catch (error) {
      addToast({
        title: 'Não foi possivel realizar o login',
        message: 'verifique seus dados e tente novamente.',
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <p>Utilize suas credenciais para entrar no sistema.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} label="E-mail" name="email" />
        <Input
          control={control}
          label="Senha"
          name="password"
          type="password"
        />
        <Link to="/forget">Recuperar sua senha.</Link>
        <Button type="submit" text="ENTRAR" />
      </form>
    </Container>
  );
}

export { Login };
