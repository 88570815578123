import { ReactNode } from 'react';
import { MdClose } from 'react-icons/md';

import { ModalWrapper, Container, Header, CloseButton } from './styles';

interface IProps {
  isOpen: boolean;
  title: string;
  onRequestClose: () => void;
  children: ReactNode;
}

ModalWrapper.setAppElement('#root');

export function Modal({
  isOpen,
  onRequestClose,
  title,
  children,
}: IProps): JSX.Element {
  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <Header>
          <h2>{title}</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose />
          </CloseButton>
        </Header>
        {children}
      </Container>
    </ModalWrapper>
  );
}
