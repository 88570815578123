import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { useToast, IToastMessage } from '../../../../contexts/toastContext';
import { Container } from './styles';

interface ToastProps {
  toastMessage: IToastMessage;
}

export function Toast({ toastMessage }: ToastProps): JSX.Element {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toastMessage.id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, toastMessage.id]);

  return (
    <Container type={toastMessage.type}>
      <div>
        <h2>{toastMessage.title}</h2>
        <p>{toastMessage.message}</p>
      </div>
      <button type="button" onClick={() => removeToast(toastMessage.id)}>
        <MdClose />
      </button>
    </Container>
  );
}
