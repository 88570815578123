import { IconType } from 'react-icons';

import { Container } from './styles';

interface ButtonMenuItemProps {
  title: string;
  description: string;
  iconComponent: IconType;
  onClick: () => void;
}

function ButtonMenuItem({
  title,
  description,
  onClick,
  iconComponent: IconComponent,
}: ButtonMenuItemProps): JSX.Element {
  return (
    <Container onClick={onClick}>
      <IconComponent size={32} />
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Container>
  );
}

export default ButtonMenuItem;
