import { ReactNode } from 'react';
import { AuthProvider } from './authContext';
import { ThemeContextProvider } from './themeContext';
import { ProfileProvider } from './profileContext';
import { LoadingProvider } from './loadingContext';
import { ToastProvider } from './toastContext';
import { CurrentCityProvider } from './currentCityContext';
import { StateCityProvider } from './stateCityContext';

interface contextsProps {
  children: ReactNode;
}

export function Contexts({ children }: contextsProps): JSX.Element {
  return (
    <>
      <ThemeContextProvider>
        <LoadingProvider>
          <ToastProvider>
            <ProfileProvider>
              <StateCityProvider>
                <CurrentCityProvider>
                  <AuthProvider>{children}</AuthProvider>
                </CurrentCityProvider>
              </StateCityProvider>
            </ProfileProvider>
          </ToastProvider>
        </LoadingProvider>
      </ThemeContextProvider>
    </>
  );
}
