import {
  Container,
  GovColorYellow,
  GovColorRed,
  GovColorBlue,
  GovColorGreen,
  GovColorBlack,
} from './styles';

export function GovTop(): JSX.Element {
  return (
    <Container>
      <GovColorYellow />
      <GovColorRed />
      <GovColorBlue />
      <GovColorGreen />
      <GovColorBlack />
    </Container>
  );
}
