import styled, { css } from 'styled-components';

interface BackgroundProps {
  isVisible: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);

  @media (max-width: 752px) {
    align-items: flex-end;
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

export const Container = styled.div`
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.background};

  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);

  width: 400px;
  height: 300px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-width: 752px) {
    width: 100%;
    height: 25vh;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  svg {
    animation: spin 2s linear infinite;
    font-size: 5rem;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  p {
    margin-top: 24px;
    font-size: 2rem;
    font-weight: bold;
  }
`;
