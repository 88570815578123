import { useEffect, useMemo } from 'react';
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { RoleEnum } from '../contants/enum/RoleEnum';
import { useAuth } from '../contexts/authContext';
import { useProfile } from '../contexts/profileContext';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  permissions?: RoleEnum[];
  component: React.ComponentType;
}

function Route({
  isPrivate,
  permissions = [],
  component: Component,
  ...rest
}: RouteProps): JSX.Element {
  const { token } = useAuth();
  const { hasPermission } = useProfile();

  const canOpenPage = useMemo(() => {
    if (isPrivate === !!token) {
      if (permissions.length && !hasPermission(permissions)) return false;
      return true;
    }
    return false;
  }, [isPrivate, token, hasPermission, permissions]);

  useEffect(() => {
    const envType = {
      dev: '(DEV) ',
      beta: '(BETA) ',
      prod: '',
    };

    const env = process.env.REACT_APP_ENV as 'dev' | 'beta' | 'prod';
    document.title = `${envType[env]} SISCOF - PB`;
  }, []);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return canOpenPage ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

Route.defaultProps = {
  isPrivate: false,
  permissions: [],
};

export default Route;
