import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';

import Button from '../../components/Button';
import Input from '../../components/Input';
import * as ResetPasswordService from '../../services/ResetPasswordService';
import { useToast } from '../../contexts/toastContext';

interface IForm {
  password: string;
  confirmPassword: string;
}

interface IParams {
  token: string;
}

const schemaResetPassword = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Senha deve conter caractere especial, letra maiúscula e minuscula e no mínimo 8 caracteres.'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'As senhas não conferem')
    .required('Campo obrigatório.'),
});

function ResetPassword(): JSX.Element {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schemaResetPassword),
  });
  const { addToast } = useToast();
  const history = useHistory();
  const params = useParams<IParams>();
  const onSubmit = useCallback(
    async ({ password, confirmPassword }: IForm) => {
      try {
        await ResetPasswordService.changePasswordWithToken({
          token: params.token,
          password,
          confirmPassword,
        });
        addToast({
          message: 'Senha redefinida',
          type: 'success',
        });

        history.push('/');
      } catch (err) {
        addToast({
          title: 'Não foi possível redefinir a senha.',
          message: 'Tente solicitar uma nova redefinição.',
          type: 'error',
        });
      }
    },
    [addToast, history, params]
  );

  return (
    <Container>
      <h2>Redefinição de senha.</h2>
      <p>Digite sua nova senha.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="password"
          control={control}
          type="password"
          label="Nova senha"
        />
        <Input
          name="confirmPassword"
          control={control}
          type="password"
          label="Confirmar senha"
        />
        <Button text="ENVIAR" type="submit" />
      </form>
    </Container>
  );
}

export { ResetPassword };
