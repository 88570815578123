import { useCallback, useEffect, useState, useMemo } from 'react';
import { MdOpenInBrowser, MdPerson } from 'react-icons/md';

import {
  Container,
  Profile,
  ProfileInfo,
  CityButton,
  ProfilePicture,
} from './styles';

import { useProfile } from '../../contexts/profileContext';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useAuth } from '../../contexts/authContext';
import { RoleEnum } from '../../contants/enum/RoleEnum';
import { Dropdown } from '../Dropdown';

function HeaderProfile(): JSX.Element {
  const { profile, hasPermission, openProfileModal } = useProfile();
  const { openModal, currentCity, isDisableButton } = useCurrentCity();
  const [profileName, setProfileName] = useState('');
  const { signOut } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    const name = profile?.name.split(' ');
    if (name) {
      if (name) {
        setProfileName(`${name[0]} ${name[name.length - 1]}`);
      }
    }
  }, [profile]);

  const handleCurrentCityOpenModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <Container>
      <h1>SISCOF/PB</h1>
      {profile && (
        <Profile>
          <ProfileInfo>
            <p>
              <strong>{profileName}</strong>
            </p>
            {hasPermission([RoleEnum.Administrador, RoleEnum.SEDH]) ? (
              <CityButton
                type="button"
                disabled={isDisableButton}
                onClick={handleCurrentCityOpenModal}
              >
                <p>{currentCity?.name}</p>
                <MdOpenInBrowser />
              </CityButton>
            ) : (
              <p>{currentCity?.name}</p>
            )}
          </ProfileInfo>
          <ProfilePicture>
            <Dropdown button={<MdPerson size={30} />}>
              <Dropdown.Item onClick={openProfileModal}>Perfil</Dropdown.Item>
              <Dropdown.Item onClick={handleSignOut}>Sair</Dropdown.Item>
            </Dropdown>
          </ProfilePicture>
        </Profile>
      )}
    </Container>
  );
}

export default HeaderProfile;
