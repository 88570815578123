import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  height: 16px;

  margin-bottom: 8px;
`;

export const GovColorYellow = styled.div`
  display: flex;
  flex: 1;
  background: #ffeb36;
`;
export const GovColorRed = styled.div`
  display: flex;
  flex: 1;
  background: #f72930;
`;
export const GovColorBlue = styled.div`
  display: flex;
  flex: 1;
  background: #399fe8;
`;
export const GovColorGreen = styled.div`
  display: flex;
  flex: 1;
  background: #00dc58;
`;
export const GovColorBlack = styled.div`
  display: flex;
  flex: 1;
  background: #0f0a0a;
`;
