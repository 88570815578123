import styled from 'styled-components';

export const Divider = styled.div`
  width: 95%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);

  align-self: center;

  margin: 16px auto 8px;
`;
