import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;

  div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ButtonActionTable = styled.button`
  background: none;
  border: none;
  padding: 1px 4px;

  &:hover {
    background: lightgray;
    border-radius: 4px;
  }
`;
