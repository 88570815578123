import { useCallback, useEffect, useState } from 'react';
import {
  MdAccountBalance,
  MdPerson,
  MdAssignment,
  MdAssignmentLate,
  MdAssignmentTurnedIn,
  MdAssignmentReturned,
  MdTrackChanges,
} from 'react-icons/md';
import { AiOutlineLoading } from 'react-icons/ai';

import { useHistory } from 'react-router-dom';
import { ButtonWrapper, Container, LoadingContainer } from './styles';

import ButtonMenuItem from '../../components/ButtonMenuItem';

import * as AccountabilityRequestService from '../../services/AccountabilityRequestService';
import AccountabilityRequest from '../../models/AccountabilityRequest';
import { useCurrentCity } from '../../contexts/currentCityContext';
import { useProfile } from '../../contexts/profileContext';
import { RoleEnum } from '../../contants/enum/RoleEnum';
import { useToast } from '../../contexts/toastContext';

function Dashboard(): JSX.Element {
  const history = useHistory();
  const { currentCity } = useCurrentCity();
  const { hasPermission } = useProfile();
  const { addToast } = useToast();

  const [
    laodingAccountabilityRequests,
    setLaodingAccountabilityRequests,
  ] = useState(true);

  const [accountabilityRequests, setAccountabilityRequests] = useState<
    AccountabilityRequest[]
  >([]);

  const handleUserList = useCallback(() => {
    history.push('/users');
  }, [history]);

  const handleBankAccountList = useCallback(() => {
    history.push('/bankAccounts');
  }, [history]);

  const handleAccountabilityRequestList = useCallback(() => {
    history.push('/accountabilityRequests');
  }, [history]);

  const handleAuditingList = useCallback(() => {
    history.push('/auditing');
  }, [history]);

  const handleAccountability = useCallback(
    (accountabilityRequest: AccountabilityRequest) => {
      if (accountabilityRequest.accountability) {
        history.push(
          `/accountabilityRequests/${accountabilityRequest.id}/accountability/${accountabilityRequest.accountability.id}`
        );
      } else
        history.push(
          `/accountabilityRequests/${accountabilityRequest.id}/accountability/new`
        );
    },
    [history]
  );

  const AccountabilityIconType = useCallback(
    (accountabilityRequest: AccountabilityRequest) => {
      if (accountabilityRequest.accountability) {
        if (accountabilityRequest.accountability.reOpen)
          return MdAssignmentReturned;
        return MdAssignmentTurnedIn;
      }

      return MdAssignmentLate;
    },
    []
  );

  useEffect(() => {
    async function loadAccountabilityRequests() {
      if (!currentCity) return;

      try {
        const data = await AccountabilityRequestService.getAll({
          cityId: currentCity.id,
        });
        setAccountabilityRequests(data.items);
      } catch (error) {
        addToast({
          type: 'error',
          message: 'Não foi possivel carregar as prestações pendentes',
        });
      } finally {
        setLaodingAccountabilityRequests(false);
      }
    }

    loadAccountabilityRequests();
  }, [currentCity, addToast]);

  return (
    <Container>
      {hasPermission([RoleEnum.Administrador, RoleEnum.SEDH]) && (
        <>
          <h2>Painel administrador</h2>
          <ButtonWrapper>
            <ButtonMenuItem
              title="Gerenciar usuarios"
              description="Cadastrar, editar e visualizar usuarios no sistema"
              iconComponent={MdPerson}
              onClick={handleUserList}
            />
            <ButtonMenuItem
              title="Gerenciar contas bancárias"
              description="Cadastrar, editar e visualizar contas no sistema"
              iconComponent={MdAccountBalance}
              onClick={handleBankAccountList}
            />
            <ButtonMenuItem
              title="Solicitar prestação de conta"
              description="Cadastrar, editar e visualizar prestações no sistema"
              iconComponent={MdAssignment}
              onClick={handleAccountabilityRequestList}
            />
            {/* <ButtonMenuItem
              title="Auditoria"
              description="Visualizar informações de auditoria"
              iconComponent={MdTrackChanges}
              onClick={handleAuditingList}
            /> */}
          </ButtonWrapper>
        </>
      )}

      <h2>Prestação de contas pendentes</h2>
      {laodingAccountabilityRequests && (
        <LoadingContainer>
          <AiOutlineLoading size={32} />
        </LoadingContainer>
      )}
      <ButtonWrapper>
        {!accountabilityRequests.length && !laodingAccountabilityRequests && (
          <p>Nenhuma prestação de conta pendente...</p>
        )}
        {accountabilityRequests.map((accountabilityRequest) => (
          <ButtonMenuItem
            key={accountabilityRequest.id}
            title={`Prestar contas ${accountabilityRequest.year}`}
            description={`Prestações de contas pendentes de ${accountabilityRequest.accountabilityType?.name} ${accountabilityRequest.year}`}
            iconComponent={AccountabilityIconType(accountabilityRequest)}
            onClick={() => handleAccountability(accountabilityRequest)}
          />
        ))}
      </ButtonWrapper>
    </Container>
  );
}

export { Dashboard };
