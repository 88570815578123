import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import ToastContainer from '../components/ToastContainer';

export interface IToastMessage {
  id: string;
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string;
  message: string;
}

interface ToastContextProps {
  children: ReactNode;
}

export interface ToastContextData {
  addToast: (message: Omit<IToastMessage, 'id'>) => void;
  removeToast: (id: string) => void;
}

const titleText = {
  success: 'Sucesso.',
  error: 'Error.',
  info: 'Informação.',
  warning: 'Aviso.',
};

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

function ToastProvider({ children }: ToastContextProps): JSX.Element {
  const [toastMessages, setToastMessages] = useState<IToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, message }: Omit<IToastMessage, 'id'>) => {
      let toastTitle = title;
      if (!title) toastTitle = titleText[type];
      setToastMessages((oldState) => [
        { id: uuidv4(), title: toastTitle, message, type },
        ...oldState,
      ]);
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    setToastMessages((oldState) => oldState.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer toastMessages={toastMessages} />
    </ToastContext.Provider>
  );
}

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  return context;
}

export { ToastProvider, useToast };
