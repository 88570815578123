import { Switch } from 'react-router-dom';

import Route from './Route';

import { Login } from '../pages/Login';

import { SendResetPassword } from '../pages/SendResetPassword';
import { ResetPassword } from '../pages/ResetPassword';

import { Dashboard } from '../pages/Dashboard';

import { UsersList } from '../pages/UsersList';
import { UsersForm } from '../pages/UsersForm';

import { BankAccountList } from '../pages/BankAccountList';
import { BankAccountForm } from '../pages/BankAccountForm';

import { AccountabilityRequestsList } from '../pages/AccountabilityRequestsList';
import { AccountabilityRequestsForm } from '../pages/AccountabilityRequestsForm';
import { AccountabilityRequestsFormBetch } from '../pages/AccountabilityRequestsFormBetch';

import { AuditingList } from '../pages/AuditingList';

import AccountabilityForm from '../pages/AccountabilityForm';

import { RoleEnum } from '../contants/enum/RoleEnum';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/reset/:token" component={ResetPassword} />
      <Route path="/forget" component={SendResetPassword} />
      <Route path="/dashboard" isPrivate component={Dashboard} />
      <Route
        path="/users"
        exact
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        component={UsersList}
      />
      <Route
        path="/users/:id"
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        component={UsersForm}
      />
      <Route
        path="/bankAccounts"
        exact
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        component={BankAccountList}
      />
      <Route
        path="/bankAccounts/:id"
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        component={BankAccountForm}
      />
      <Route
        path="/accountabilityRequests"
        exact
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        component={AccountabilityRequestsList}
      />
      <Route
        path="/accountabilityRequests/:id"
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        exact
        component={AccountabilityRequestsForm}
      />
      <Route
        path="/accountabilityRequests/betch/new"
        isPrivate
        permissions={[RoleEnum.Administrador, RoleEnum.SEDH]}
        exact
        component={AccountabilityRequestsFormBetch}
      />
      <Route
        path="/accountabilityRequests/:accountabilityRequestsId/accountability/:accountabilityId"
        isPrivate
        exact
        component={AccountabilityForm}
      />
      <Route
        path="/auditing"
        exact
        isPrivate
        permissions={[RoleEnum.Administrador]}
        component={AuditingList}
      />
    </Switch>
  );
}
