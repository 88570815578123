import styled, { css } from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex: 1;
    flex-direction: column;

    align-items: flex-end;
  }
`;

export const CitiesWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.border};
  `}
`;

export const CityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CityInputWrapper = styled.div`
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.border};
  `}
`;
